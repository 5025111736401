import { type FC, useState } from "react"
import styled, { css } from "styled-components"

import { toPriceString } from "@forento/shared/utilities/currency"

import Button from "~/themes/school/components/Button"
import Card, {
	CardHeader,
	CardSticker,
	CardText,
	CardThumbnail,
	CardTitle,
	Cards,
} from "~/themes/school/components/Card"
import { TitleSearch } from "~/themes/school/components/ContentSearch"
import { CheckIcon } from "~/themes/school/components/Icon"
import Layout from "~/themes/school/components/Layout"
import PageHeader, { PageTitle } from "~/themes/school/components/PageHeader"
import PartialLoadingPage from "~/themes/school/components/PartialLoadingPage"
import { fonts, successColor } from "~/themes/school/styles"
import { useTranslation } from "~/translations"
import routes from "~/utilities/routes"
import { query } from "~/utilities/trpc"

const CoursesPage: FC = () => {
	const tags = query.course.listTags.useQuery()
	const courses = query.course.listPersonalized.useQuery()
	const t = useTranslation()

	const [selectedTag, setSelectedTag] = useState<number>()
	const [searchItems, setSearchItems] = useState<number[]>()

	return (
		<Layout>
			<PageHeader>
				<PageTitle>{t("course.title")}</PageTitle>
				<TitleSearch items={courses.data ?? []} onItemsToShowChange={setSearchItems} />
			</PageHeader>
			{tags.error || courses.error ? (
				<p>{t("failedToLoad")}</p>
			) : courses.data === undefined || tags.data === undefined ? (
				<PartialLoadingPage />
			) : (
				<>
					{tags.data.length > 0 && (
						<Tags>
							{tags.data.map(tag => (
								<Tag
									key={tag.id}
									$isActive={selectedTag === tag.id}
									onClick={() => setSelectedTag(current => (current !== tag.id ? tag.id : undefined))}
								>
									{tag.label}
								</Tag>
							))}
							{selectedTag !== undefined && (
								<Tag $isActive onClick={() => setSelectedTag(undefined)}>
									{t("clearFilter")}
								</Tag>
							)}
						</Tags>
					)}
					<Cards>
						{(selectedTag !== undefined
							? courses.data.filter(course => course.tags.some(tag => tag.id === selectedTag))
							: courses.data
						)
							.filter(x => searchItems?.includes(x.id) ?? true)
							.sort((a, b) => (searchItems ? searchItems.indexOf(b.id) - searchItems.indexOf(a.id) : 0))
							.map(course => (
								<Card key={course.id} as={Button} onClick={routes.course.detail(course.id)}>
									<CardHeader>
										<CourseTitle
											$isCompleted={course.status === "unlocked" && course.progress === 1}
										>
											{course.title}
											{course.status === "unlocked" && course.progress === 1 && (
												<CourseTitleCheckIcon />
											)}
										</CourseTitle>
										<CardSticker>
											{course.status === "unlocked" && course.pages.completed > 0
												? `${course.pages.completed}/${course.pages.total}`
												: `${t("course.numberOfPages", { count: course.pageCount })}`}
										</CardSticker>
									</CardHeader>
									<CardThumbnail
										filePath={course.thumbnailFilePath}
										sticker={
											<Price>
												{course.price
													? `${toPriceString(course.price)}`
													: course.membershipIds.length > 0
														? t("membership.title")
														: t("product.priceFree")}
											</Price>
										}
									/>
									<CardText>{course.shortDescription}</CardText>
								</Card>
							))}
					</Cards>
				</>
			)}
		</Layout>
	)
}

const Tags = styled.div`
	width: 100%;
	display: flex;
	gap: 22px;
	flex-wrap: wrap;
	margin-bottom: 24px;
`

const Tag = styled(Button)<{ $isActive: boolean }>`
	font-family: ${fonts.quicksand};
	font-size: 14px;
	font-weight: bold;
	color: ${props => (props.$isActive ? props.theme.primaryColor : "#454D66")};
`

const CourseTitle = styled(CardTitle)<{ $isCompleted: boolean }>`
	${props =>
		props.$isCompleted &&
		css`
			color: ${successColor};
			display: flex;
			align-items: center;
			gap: 8px;
		`}
`

const CourseTitleCheckIcon = styled(CheckIcon)`
	width: 12px;
	height: 12px;
`

const Price = styled.p`
	position: absolute;
	top: 16px;
	right: 16px;
	font-weight: bold;
	font-size: 16px;
	color: #ffa412;
	background-color: white;
	padding: 4px 8px;
`

export default CoursesPage
