import { type FC } from "react"

import { useAlert } from "~/contexts/AlertContext"
import Checkbox from "~/themes/school/components/Checkbox"
import PartialLoadingPage from "~/themes/school/components/PartialLoadingPage"
import { useTranslation } from "~/translations"
import { query } from "~/utilities/trpc"

const Notifications: FC = () => {
	const t = useTranslation()
	const alert = useAlert()

	const { data, error, refetch } = query.user.getStudentNotificationSettings.useQuery()

	const update = query.user.setStudentNotificationSetting.useMutation({
		async onSuccess() {
			await refetch()
		},
		async onError(error) {
			console.error(error)
			await alert.show("Error", "Failed to update notification settings.")
		},
	})

	if (error) return <p>{t("failedToLoad")}</p>

	if (data === undefined) return <PartialLoadingPage />

	return (
		<>
			<Checkbox
				label={t("user.notification.newMessage")}
				isChecked={data.newMessage}
				onChange={() => update.mutate({ setting: "newMessage", value: !data.newMessage })}
				isLoading={update.isPending && update.variables?.setting === "newMessage"}
			/>
			<Checkbox
				label={t("user.notification.newCommunityPost")}
				isChecked={data.newCommunityPost}
				onChange={() => update.mutate({ setting: "newCommunityPost", value: !data.newCommunityPost })}
				isLoading={update.isPending && update.variables?.setting === "newCommunityPost"}
			/>
		</>
	)
}

export default Notifications
