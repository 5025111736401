import { type FC, useState } from "react"
import styled from "styled-components"

import { toPriceString } from "@forento/shared/utilities/currency"

import Button from "~/themes/school/components/Button"
import Card, {
	CardHeader,
	CardSticker,
	CardText,
	CardThumbnail,
	CardTitle,
	Cards,
} from "~/themes/school/components/Card"
import { TitleSearch } from "~/themes/school/components/ContentSearch"
import Layout from "~/themes/school/components/Layout"
import PageHeader, { PageTitle } from "~/themes/school/components/PageHeader"
import PartialLoadingPage from "~/themes/school/components/PartialLoadingPage"
import { useTranslation } from "~/translations"
import routes from "~/utilities/routes"
import { query } from "~/utilities/trpc"

const SessionsPage: FC = () => {
	const t = useTranslation()

	const [searchItems, setSearchItems] = useState<number[]>()

	const { data: sessions, error } = query.privateSession.list.useQuery()

	return (
		<Layout>
			<PageHeader>
				<PageTitle>{t("session.title")}</PageTitle>
				<TitleSearch items={sessions ?? []} onItemsToShowChange={setSearchItems} />
			</PageHeader>
			{error ? (
				<p>{t("failedToLoad")}</p>
			) : sessions === undefined ? (
				<PartialLoadingPage />
			) : (
				<Cards>
					{sessions
						.filter(x => searchItems?.includes(x.id) ?? true)
						.sort((a, b) => (searchItems ? searchItems.indexOf(b.id) - searchItems.indexOf(a.id) : 0))
						.map(session => (
							<Card key={session.id} as={Button} onClick={routes.session.detail(session.id)}>
								<CardHeader>
									<CardTitle>{session.title}</CardTitle>
									<SessionPrice>
										{session.price !== null
											? toPriceString(session.price)
											: session.membershipIds.length > 0
												? t("membership.title")
												: t("product.priceFree")}
									</SessionPrice>
								</CardHeader>
								<CardThumbnail filePath={session.thumbnailFilePath} />
								{session.shortDescription && <CardText>{session.shortDescription}</CardText>}
							</Card>
						))}
				</Cards>
			)}
		</Layout>
	)
}

const SessionPrice = styled(CardSticker)`
	font-weight: 700;
	color: #ffa412;
`

export default SessionsPage
