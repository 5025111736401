import { type ReactNode, useState, useMemo, type FC, useEffect } from "react"
import { createPortal } from "react-dom"
import styled, { css, keyframes } from "styled-components"

type Props = {
	tooltip: string
	inline?: boolean
	showInfoIndicator?: boolean
	className?: string
	children?: ReactNode
}
const Tooltip: FC<Props> = ({ tooltip, inline = false, showInfoIndicator = false, className, children }) => {
	const [state, setState] = useState<"idle" | "open" | "closed">("idle")

	const [containerElement, setContainerElement] = useState<HTMLDivElement | null>(null)
	const [tooltipElement, setTooltipElement] = useState<HTMLDivElement | null>(null)

	const position = useMemo(() => {
		if (containerElement === null) return { x: 0, y: 0 }

		const tooltipWidth = Math.min(tooltipElement?.offsetWidth ?? 0, 300)
		const bounds = containerElement.getBoundingClientRect()
		return {
			x: Math.min(window.innerWidth - tooltipWidth / 2, bounds.x + bounds.width / 2),
			y: window.scrollY + bounds.y + bounds.height + 16,
		}
	}, [containerElement, tooltipElement])

	useEffect(() => {
		if (state !== "closed") return
		const timeout = setTimeout(() => setState("idle"), 1000)
		return () => clearTimeout(timeout)
	}, [state])

	return (
		<Container
			ref={setContainerElement}
			onMouseEnter={() => setState("open")}
			onMouseLeave={() => setState("closed")}
			$inline={inline}
		>
			<Children $inline={inline} className={className}>
				{children}
			</Children>
			{showInfoIndicator && <InfoIndicator />}
			{state !== "idle" &&
				createPortal(
					<Content ref={setTooltipElement} $position={position} $isOpen={state === "open"}>
						{tooltip}
					</Content>,
					document.body,
				)}
		</Container>
	)
}

const Container = styled.div<{ $inline: boolean }>`
	display: flex;
	align-items: center;
	gap: 6px;

	${props =>
		props.$inline &&
		css`
			display: inline;
		`}
`

const Children = styled.div<{ $inline: boolean }>`
	cursor: default;

	${props =>
		props.$inline &&
		css`
			display: inline;
		`}
`

const openAnimation = keyframes`
	from { scale: 0; }
	to { scale: 1; }
`

const closeAnimation = keyframes`
	from { scale: 1; }
	to { scale: 0; }
`

const Content = styled.div<{ $position: { x: number; y: number } | null; $isOpen: boolean }>`
	position: absolute;
	left: ${props => props.$position?.x ?? 0}px;
	top: ${props => props.$position?.y ?? 0}px;
	background-color: black;
	border-radius: 2px;
	padding: 8px 12px;
	color: white;
	font-size: 16px;
	font-weight: normal;
	text-align: center;
	translate: -50%;
	scale: 0;
	transform-origin: top center;
	pointer-events: none;
	z-index: 2000;

	${props =>
		props.$isOpen
			? css`
					animation: ${openAnimation} 0.15s forwards;
				`
			: css`
					animation: ${closeAnimation} 0.15s forwards;
				`}
`

const InfoIcon: FC<{ className?: string }> = ({ className }) => (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className={className}>
		<path d="M0 0h24v24H0V0z" fill="none" />
		<path d="M11 7h2v2h-2zm0 4h2v6h-2zm1-9C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z" />
	</svg>
)

const InfoIndicator = styled(InfoIcon)`
	width: 12px;
	height: 12px;
`

export default Tooltip
