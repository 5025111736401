import { type ReactNode, Suspense, lazy } from "react"
import styled, { createGlobalStyle } from "styled-components"

import { usePlatform } from "~/contexts/PlatformContext"
import Button from "~/themes/original/components/Button"
import Logo from "~/themes/original/components/Logo"
import { lightTextColor } from "~/utilities/styles"

const DefaultThumbnail = lazy(() => import("./DefaultThumbnail"))

const GlobalStyle = createGlobalStyle`
	* {
		font-family: "Poppins", sans-serif;
	}
`

const Layout: React.FC<{ onSubmit?: () => void; children: ReactNode }> = ({ onSubmit, children }) => {
	const platform = usePlatform().platform

	return (
		<Container>
			<link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700" />
			<GlobalStyle />
			{platform.thumbnailFilePath !== null ? (
				<CustomPlatformThumbnail backgroundImage={platform.thumbnailFilePath}>
					<StyledLogo />
				</CustomPlatformThumbnail>
			) : (
				<PlatformThumbnail>
					<Suspense>
						<DefaultThumbnail />
					</Suspense>
				</PlatformThumbnail>
			)}
			{onSubmit !== undefined ? (
				<Content
					onSubmit={event => {
						event.preventDefault()
						onSubmit()
					}}
				>
					{children}
				</Content>
			) : (
				<Content as="div">{children}</Content>
			)}
		</Container>
	)
}

const Container = styled.div`
	width: 100%;
	min-height: 100vh;
	position: relative;
	display: flex;
`

const PlatformThumbnail = styled.div`
	flex: 1 0 0;
	max-width: calc(50% - ${44 * 2}px);
	display: none;
	margin: 44px;
	border-radius: 16px;

	@media (min-width: 1000px) {
		display: flex;
		align-items: center;
	}
`

const CustomPlatformThumbnail = styled(PlatformThumbnail)<{ backgroundImage: string }>`
	background-image: url(${props => props.backgroundImage});
	background-size: cover;
	background-position: center;
`

const StyledLogo = styled(Logo)`
	color: white;
	margin: 44px;
`

const Content = styled.form`
	flex: 1 0 0;
	max-width: 525px;
	background-color: white;
	display: flex;
	flex-direction: column;
	align-items: stretch;
	padding: 96px 16px 16px;
	gap: 32px;

	@media (min-width: 1000px) {
		justify-content: center;
		padding: 44px;
	}
`

export const Intro = styled.div`
	display: flex;
	flex-direction: column;
	gap: 12px;
	margin-bottom: 10px;
`

export const Title = styled.h1`
	font-size: 24px;
	font-weight: 600;
	color: #151d48;
	text-align: center;

	@media (min-width: 1000px) {
		text-align: left;
	}
`

export const Description = styled.p`
	font-size: 16px;
	color: ${lightTextColor};
	text-align: center;

	@media (min-width: 1000px) {
		text-align: left;
	}
`

export const InputFields = styled.div`
	display: flex;
	flex-direction: column;
	gap: 32px;
`

export const InputFieldRow = styled.div`
	display: flex;
	gap: 24px;

	@media (max-width: 999px) {
		flex-direction: column;
		gap: 32px;
	}
`

export const NavigationLinks = styled.div`
	display: flex;
	flex-direction: column;
	gap: 12px;
`

export const NavigationLink = styled.div`
	font-size: 16px;
	color: ${lightTextColor};
	display: flex;
	gap: 8px;
	align-items: center;
`

export const NavigationLinkButton = styled(Button)`
	font-size: 16px;
	color: ${props => props.theme.primaryColor};
`

export default Layout
