import { useState, type FC } from "react"
import { Navigate, useParams } from "react-router"
import styled from "styled-components"

import { parseNumber } from "@forento/shared/utilities/number"

import { usePlatform } from "~/contexts/PlatformContext"
import { useUser } from "~/contexts/UserContext"
import Button from "~/themes/school/components/Button"
import Hero from "~/themes/school/components/Hero"
import Layout from "~/themes/school/components/Layout"
import PageHeader, { PageBreadcrumb } from "~/themes/school/components/PageHeader"
import PartialLoadingPage from "~/themes/school/components/PartialLoadingPage"
import { useTranslation } from "~/translations"
import routes from "~/utilities/routes"
import trpc, { query } from "~/utilities/trpc"

import PriceButton from "./PriceButton"

const MembershipPage: FC = () => {
	const membershipId = parseNumber(useParams().membershipId)
	const platform = usePlatform().platform
	const user = useUser()
	const t = useTranslation()

	const [submittingPriceId, setSubmittingPriceId] = useState<number>()
	const [isUpdatingPaymentMethod, setUpdatingPaymentMethod] = useState(false)

	const { data: membership, error } = query.membership.getPublic.useQuery(membershipId ?? -1)

	if (membership === null) return <Navigate to={routes.membership.index()} replace />

	async function handleUpdatePaymentMethod() {
		setUpdatingPaymentMethod(true)
		try {
			const { redirectUrl } = await trpc.payment.stripe.updatePaymentMethod.mutate()
			window.location.href = redirectUrl
		} catch {
			console.error("Failed to update student payment method!")
			setUpdatingPaymentMethod(false)
		}
	}

	const isActive =
		membership !== undefined &&
		user.user?.membership?.id === membership.id &&
		(user.user.membership.endDate === null || user.user.membership.endDate > new Date())

	return (
		<Layout>
			<PageHeader>
				<PageBreadcrumb
					title={membership?.title ?? "..."}
					path={[{ title: t("membership.title"), link: routes.membership.index() }]}
				/>
				{isActive && platform.paymentGateway?.type === "stripe" && (
					<Button variant="secondary" onClick={handleUpdatePaymentMethod} isLoading={isUpdatingPaymentMethod}>
						{t("billing.updatePaymentMethod")}
					</Button>
				)}
			</PageHeader>

			{error ? (
				<p>{t("failedToLoad")}</p>
			) : !membership ? (
				<PartialLoadingPage />
			) : (
				<Hero
					title={membership.title}
					htmlDescription={membership.longDescription?.html}
					thumbnail={membership.thumbnailFilePath && <Thumbnail src={membership.thumbnailFilePath} />}
					callToAction={
						user.user?.isCreator ? (
							<CreatorWarning>{t("membership.isCreator")}</CreatorWarning>
						) : (
							<CallToActions>
								{membership.prices.map(price => {
									return (
										<PriceButton
											key={price.id}
											membershipId={membership.id}
											isActiveMembership={isActive}
											price={price}
											setSubmittingPriceId={setSubmittingPriceId}
											submittingPriceId={submittingPriceId}
										/>
									)
								})}
							</CallToActions>
						)
					}
				/>
			)}
		</Layout>
	)
}

const Thumbnail = styled.img.attrs({ alt: "Membership thumbnail" })`
	width: 100%;
	height: 100%;
	object-fit: cover;
`

const CreatorWarning = styled.div`
	font-style: italic;
`

const CallToActions = styled.div`
	display: flex;
	flex-direction: column;
	gap: 12px;
`

export default MembershipPage
