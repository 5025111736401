import { type FC, useState } from "react"
import { useLocation, useNavigate } from "react-router"

import { parseNumber } from "@forento/shared/utilities/number"

import { SubmitButton } from "~/themes/original/components/Button"
import InputField from "~/themes/original/components/InputField"
import LoadingIndicator from "~/themes/original/components/LoadingIndicator"
import routes from "~/utilities/routes"
import trpc, { query } from "~/utilities/trpc"

import Layout, { Title, Description } from "./Layout"

const CompleteProfilePage: FC = () => {
	const location = useLocation()
	const navigate = useNavigate()

	const params = new URLSearchParams(location.search)

	const [firstName, setFirstName] = useState("")
	const [lastName, setLastName] = useState("")
	const [password, setPassword] = useState("")
	const [passwordConfirmation, setPasswordConfirmation] = useState("")
	const [isSubmitting, setSubmitting] = useState(false)

	const userId = parseNumber(params.get("user") ?? "") ?? -1
	const token = params.get("token") ?? ""

	const tokenCheck = query.user.checkCompleteProfileToken.useQuery({ userId, token })

	const isValid =
		password.length > 0 &&
		password === passwordConfirmation &&
		firstName.trim().length > 0 &&
		lastName.trim().length > 0

	async function submit() {
		if (!isValid || tokenCheck.data?.status !== "success") return

		setSubmitting(true)
		try {
			await trpc.user.completeProfileFromToken.mutate({ userId, token, data: { firstName, lastName, password } })
			navigate(`${routes.user.signin()}?password-reset-email=${encodeURIComponent(tokenCheck.data.email)}`)
		} finally {
			setSubmitting(false)
		}
	}

	return (
		<Layout onSubmit={submit}>
			<Title>Complete profile</Title>

			{!tokenCheck.data ? (
				<LoadingIndicator />
			) : tokenCheck.data.status === "invalid" ? (
				<Description>Invalid complete profile link. It may be expired or already used.</Description>
			) : (
				<>
					<InputField label="Email address" value={tokenCheck.data.email} disabled />

					<InputField
						label="First name"
						value={firstName}
						onChange={setFirstName}
						autoComplete="given-name"
					/>

					<InputField label="Last name" value={lastName} onChange={setLastName} autoComplete="family-name" />

					<InputField
						inputType="password"
						label="New password"
						value={password}
						onChange={setPassword}
						autoComplete="new-password"
					/>

					<InputField
						inputType="password"
						label="Confirm new password"
						value={passwordConfirmation}
						onChange={setPasswordConfirmation}
						autoComplete="new-password"
					/>

					<SubmitButton
						variant="primary"
						isLoading={isSubmitting}
						isDisabled={!isValid || tokenCheck.data?.status !== "success"}
					>
						Complete profile
					</SubmitButton>
				</>
			)}
		</Layout>
	)
}

export default CompleteProfilePage
