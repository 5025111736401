import { type FC } from "react"
import { useLocation } from "react-router"
import styled, { css } from "styled-components"

import { usePlatform } from "~/contexts/PlatformContext"
import Button from "~/themes/original/components/Button"
import { useTranslation } from "~/translations"
import routes from "~/utilities/routes"
import { lightTextColor } from "~/utilities/styles"

const navItems = [
	{ label: "Terms of service", onClick: routes.policy.termsOfService() },
	{ label: "Privacy policy", onClick: routes.policy.privacy() },
	{ label: "Cookie policy", onClick: routes.policy.cookie() },
]

const Footer: FC = () => {
	const platform = usePlatform().platform
	const location = useLocation()
	const t = useTranslation()

	return (
		<Container>
			<Nav>
				<NavItem>
					© {new Date().getFullYear()} {platform.name}
				</NavItem>
				{navItems.map(item => (
					<NavItem
						key={item.label}
						as={Button}
						onClick={item.onClick}
						isActive={location.pathname === item.onClick}
					>
						{item.label}
					</NavItem>
				))}
			</Nav>
			{platform.hasWatermark && (
				<div>
					<Watermark newTab onClick="https://forento.io?s=3546">
						{t("general.watermark.title")}
					</Watermark>
					<WatermarkText>{t("general.watermark.text")}</WatermarkText>
				</div>
			)}
		</Container>
	)
}

const Container = styled.div`
	padding: 42px;
	display: flex;
	flex-direction: column;
	gap: 16px;
`

const Nav = styled.div`
	display: flex;
	align-items: center;
	gap: 16px;

	@media (max-width: 449px) {
		flex-direction: column;
	}
`

const NavItem = styled.div<{ isActive?: boolean }>`
	font-size: 16px;
	color: ${lightTextColor};
	letter-spacing: 0.6px;

	${props =>
		props.isActive &&
		css`
			font-weight: 700;
			letter-spacing: 0;
			color: ${props => props.theme.primaryColor};
		`}
`

const Watermark = styled(Button)`
	font-weight: 600;
	font-size: 16px;
	margin-bottom: 4px;
`

const WatermarkText = styled.div`
	color: ${lightTextColor};
	font-size: 14px;
`

export default Footer
