import { useState } from "react"
import { useParams } from "react-router"
import styled from "styled-components"

import { type PersonalizedCourse } from "@forento/shared/models/course"
import { parseNumber } from "@forento/shared/utilities/number"

import { useCourseDetail } from "~/api/course"
import { PurchaseSuccessModalContextProvider } from "~/contexts/PurchaseSuccessModalContext"
import useEditorJsDisplay from "~/hooks/useEditorJsDisplay"
import CourseComments from "~/themes/original/components/CourseComments"
import FullLoadingPage from "~/themes/original/components/FullLoadingPage"
import Layout from "~/themes/original/components/Layout"
import { ControlledTabs } from "~/themes/original/components/Tabs"
import { lightTextColor } from "~/utilities/styles"

import NotFoundPage from "../../NotFoundPage"
import ChaptersList from "./ChaptersList"
import Hero from "./Hero"
import Resources from "./Resources"

const CourseDetailPage: React.FC = () => {
	const courseId = parseNumber(useParams().courseId!)
	const [data, reload] = useCourseDetail(courseId ?? -1)

	const [longDescriptionElement, setLongDescriptionElement] = useState<HTMLElement | null>(null)

	useEditorJsDisplay(longDescriptionElement, data.status === "success" ? data.course.longDescription : null)

	switch (data.status) {
		case "error":
			return <Layout>Failed to load course.</Layout>
		case "loading":
			return <FullLoadingPage />
		case "not-found":
			return <NotFoundPage />
		case "success": {
			const course = data.course

			return (
				<PurchaseSuccessModalContextProvider
					content={{
						type: "course",
						id: course.id,
						isUnlocked: course.status === "unlocked",
						payPalOrderId:
							course.status === "unlocked" &&
							course.unlockedCause === "purchased" &&
							course.order.type === "paypal"
								? course.order.id
								: null,
					}}
				>
					<Layout
						metadata={{
							title: data.title,
							description: course.shortDescription,
							imageUrl: course.thumbnailFilePath ?? undefined,
						}}
					>
						<Content>
							<Hero data={data} reload={reload} />
							<ControlledTabs tabs={getTabs(course, reload, setLongDescriptionElement)} />
						</Content>
					</Layout>
				</PurchaseSuccessModalContextProvider>
			)
		}
	}
}

function getTabs(
	course: PersonalizedCourse,
	reloadCourse: () => Promise<unknown>,
	setLongDescriptionElement: (element: HTMLElement | null) => void,
) {
	const tabs = []
	if (course.longDescription !== null) {
		tabs.push({
			title: "Overview",
			content: (
				<TabContent>
					<AboutContent>
						<div ref={setLongDescriptionElement} />
					</AboutContent>
				</TabContent>
			),
		})
	}
	tabs.push({
		title: "Content",
		content: (
			<TabContent>
				<ChaptersList chapters={course.chapters} />
			</TabContent>
		),
	})
	if (course.status === "unlocked" && course.resources.length > 0) {
		tabs.push({
			title: "Resources",
			content: (
				<TabContent>
					<Resources resources={course.resources} />
				</TabContent>
			),
		})
	}
	if (course.commentsEnabled) {
		tabs.push({
			title: "Comments",
			content: (
				<TabContent>
					<CourseComments
						courseId={course.id}
						commentsUnderReview={course.status === "unlocked" ? course.commentsUnderReview : 0}
						pageId={null}
						reloadCourse={reloadCourse}
					/>
				</TabContent>
			),
		})
	}
	return tabs
}

const Content = styled.div`
	@media (max-width: 999px) {
		margin: 0 16px;
	}
`

const TabContent = styled.div`
	margin-top: 32px;
`

const AboutContent = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 12px;

	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		font-weight: 600;
		font-size: 20px;
		color: #151d48;
	}

	> p {
		font-size: 16px;
		color: ${lightTextColor};
	}
`

export default CourseDetailPage
