import { useState, type FC } from "react"
import { Navigate, useParams } from "react-router"
import styled from "styled-components"

import AspectRatio from "@forento/shared/components/AspectRatio"
import Tooltip from "@forento/shared/components/Tooltip"
import VideoPlayer from "@forento/shared/components/VideoPlayer"
import { type DetailedCoursePage } from "@forento/shared/models/course"
import { toDateTimeString } from "@forento/shared/utilities/date"
import { parseNumber } from "@forento/shared/utilities/number"
import { exhaustiveGuard } from "@forento/shared/utilities/switch"

import { useCoursePlayer } from "~/api/course"
import { usePlatform } from "~/contexts/PlatformContext"
import useEditorJsDisplay from "~/hooks/useEditorJsDisplay"
import Button from "~/themes/original/components/Button"
import CourseComments from "~/themes/original/components/CourseComments"
import Layout from "~/themes/original/components/Layout"
import PartialLoadingPage from "~/themes/original/components/PartialLoadingPage"
import { toRelativeDateString } from "~/themes/original/utilities/date"
import { mediaQueries } from "~/utilities/dimensions"
import routes from "~/utilities/routes"
import { lightTextColor } from "~/utilities/styles"

import Attachments from "./Attachments"
import CertificateImage from "./CertificateImage"
import ChaptersList from "./ChaptersList"
import Resources from "./Resources"
import InputAnswerType from "./answers/InputAnswerType"
import MultiAnswerType from "./answers/MultiAnswerType"
import SingleAnswerType from "./answers/SingleAnswerType"

const CoursePlayerPage: FC = () => {
	const courseId = parseNumber(useParams().courseId!)
	const platform = usePlatform().platform

	const player = useCoursePlayer(courseId ?? -1)

	const [isCertificateShown, setCertificateShown] = useState(false)

	if (player.status === "error") return <>Failed to load course.</>
	if (player.status === "loading") return <PartialLoadingPage />
	if (player.status === "not-found") return <Navigate to={routes.course.index()} />

	return (
		<StyledLayout>
			{player.current === null ? (
				platform.content.certificates && player.course.issueCertificates && !isCertificateShown ? (
					<PageContent>
						<CertificateImage />
						<CourseCompletedTitle>
							Congratulations! You have received a certificate for completing the course!
						</CourseCompletedTitle>
						<Buttons>
							<StyledButton variant="secondary" onClick={player.previousPage}>
								Previous
							</StyledButton>
							<StyledButton variant="secondary" onClick={routes.certificate.index()}>
								Go to all certificates
							</StyledButton>
							<StyledButton variant="primary" onClick={() => setCertificateShown(true)}>
								Continue
							</StyledButton>
						</Buttons>
					</PageContent>
				) : (
					<PageContent>
						<CourseCompletedTitle>You've completed the course. Well done!</CourseCompletedTitle>
						<Buttons>
							<StyledButton variant="secondary" onClick={player.previousPage}>
								Previous
							</StyledButton>
							<StyledButton variant="primary" onClick={routes.course.index()}>
								Go to all courses
							</StyledButton>
						</Buttons>
					</PageContent>
				)
			) : player.current.status === "locked" ? (
				<PageContent>
					<CourseCompletedTitle>This page is locked</CourseCompletedTitle>
					<Buttons>
						<StyledButton variant="secondary" onClick={player.previousPage}>
							Previous
						</StyledButton>
						<StyledButton variant="primary" onClick={routes.course.index()}>
							Go to all courses
						</StyledButton>
					</Buttons>
				</PageContent>
			) : player.current.status === "not-dripped" ? (
				<PageContent>
					<PageTitle>This chapter is not yet dripped</PageTitle>
					<PageText>
						This chapter will be available{" "}
						<Tooltip inline tooltip={toDateTimeString(player.current.dripDate)}>
							<DripDate dateTime={player.current.dripDate.toISOString()}>
								{toRelativeDateString(player.current.dripDate)}
							</DripDate>
						</Tooltip>
						.
					</PageText>
					<Buttons>
						<StyledButton variant="secondary" onClick={player.previousPage}>
							Previous
						</StyledButton>
						<StyledButton variant="primary" onClick={routes.course.index()}>
							Go to all courses
						</StyledButton>
					</Buttons>
				</PageContent>
			) : player.current.page.type === "custom" ? (
				<CustomPage
					page={player.current.page}
					isNextPageEnabled={player.isNextPageEnabled}
					onPreviousPage={player.previousPage}
					onNextPage={player.nextPage}
					onSkipChapter={player.skipChapter}
				/>
			) : player.current.page.type === "youtube" ? (
				<PageContent>
					<EmbeddedVideoContainer width={{ unit: "percent", value: 100 }} aspectRatio={16 / 9}>
						<iframe
							width="100%"
							height="100%"
							style={{ border: "none" }}
							src={`https://www.youtube-nocookie.com/embed/${player.current.page.youTubeVideoId}`}
							title="YouTube video player"
							allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
							referrerPolicy="strict-origin-when-cross-origin"
							allowFullScreen
						/>
					</EmbeddedVideoContainer>
					<PageTitle>{player.current.page.title}</PageTitle>
					{player.current.page.htmlContent !== null && (
						<PageHtmlText dangerouslySetInnerHTML={{ __html: player.current.page.htmlContent }} />
					)}
					<Attachments links={player.current.page.links} attachments={player.current.page.attachments} />
					<Buttons>
						<PreviousButton variant="secondary" onClick={player.previousPage}>
							Previous
						</PreviousButton>
						<NextButton variant="primary" onClick={player.nextPage}>
							Continue
						</NextButton>
						<SkipChapterButton variant="secondary" onClick={player.skipChapter}>
							Skip chapter
						</SkipChapterButton>
					</Buttons>
				</PageContent>
			) : player.current.page.type === "vimeo" ? (
				<PageContent>
					<EmbeddedVideoContainer width={{ unit: "percent", value: 100 }} aspectRatio={16 / 9}>
						<iframe
							width="100%"
							height="100%"
							style={{ border: "none" }}
							src={`https://player.vimeo.com/video/${player.current.page.vimeoVideoId}?h=fa26a9e8f5&loop=1&title=0&byline=0&portrait=0&badge=0`}
							allow="autoplay; fullscreen; picture-in-picture"
							allowFullScreen
						/>
					</EmbeddedVideoContainer>
					<PageTitle>{player.current.page.title}</PageTitle>
					{player.current.page.htmlContent !== null && (
						<PageHtmlText dangerouslySetInnerHTML={{ __html: player.current.page.htmlContent }} />
					)}
					<Attachments links={player.current.page.links} attachments={player.current.page.attachments} />
					<Buttons>
						<PreviousButton variant="secondary" onClick={player.previousPage}>
							Previous
						</PreviousButton>
						<NextButton variant="primary" onClick={player.nextPage}>
							Continue
						</NextButton>
						<SkipChapterButton variant="secondary" onClick={player.skipChapter}>
							Skip chapter
						</SkipChapterButton>
					</Buttons>
				</PageContent>
			) : player.current.page.type === "video" ? (
				<PageContent>
					<StyledVideoPlayer video={player.current.page.video} />
					<PageTitle>{player.current.page.title}</PageTitle>
					{player.current.page.htmlContent !== null && (
						<PageHtmlText dangerouslySetInnerHTML={{ __html: player.current.page.htmlContent }} />
					)}
					<Attachments links={player.current.page.links} attachments={player.current.page.attachments} />
					<Buttons>
						<PreviousButton variant="secondary" onClick={player.previousPage}>
							Previous
						</PreviousButton>
						<NextButton variant="primary" onClick={player.nextPage}>
							Continue
						</NextButton>
						<SkipChapterButton variant="secondary" onClick={player.skipChapter}>
							Skip chapter
						</SkipChapterButton>
					</Buttons>
				</PageContent>
			) : player.current.page.type === "input" ? (
				<PageContent>
					<PageImage image={player.current.page.image} />
					<PageTitle>{player.current.page.title}</PageTitle>
					{player.current.page.htmlContent !== null && (
						<PageHtmlText dangerouslySetInnerHTML={{ __html: player.current.page.htmlContent }} />
					)}
					<Attachments links={player.current.page.links} attachments={player.current.page.attachments} />
					<Answers>
						<InputAnswerType inputType={player.current.page.inputType} onChange={player.setSelection} />
					</Answers>
					<Buttons>
						<PreviousButton variant="secondary" onClick={player.previousPage}>
							Previous
						</PreviousButton>
						<NextButton variant="primary" onClick={player.nextPage} isDisabled={!player.isNextPageEnabled}>
							Continue
						</NextButton>
						<SkipChapterButton variant="secondary" onClick={player.skipChapter}>
							Skip chapter
						</SkipChapterButton>
					</Buttons>
				</PageContent>
			) : player.current.page.type === "quiz" ? (
				<PageContent>
					<PageImage image={player.current.page.image} />
					<PageTitle>{player.current.page.title}</PageTitle>
					{player.current.page.htmlContent !== null && (
						<PageHtmlText dangerouslySetInnerHTML={{ __html: player.current.page.htmlContent }} />
					)}
					<Attachments links={player.current.page.links} attachments={player.current.page.attachments} />
					<Answers>
						{player.current.page.quiz.type === "single-answer" ? (
							<SingleAnswerType
								answers={player.current.page.quiz.answers}
								setSelection={player.setSelection}
							/>
						) : player.current.page.quiz.type === "multi-answer" ? (
							<MultiAnswerType
								answers={player.current.page.quiz.answers}
								setSelection={player.setSelection}
							/>
						) : (
							exhaustiveGuard(player.current.page.quiz.type)
						)}
					</Answers>
					<Buttons>
						<PreviousButton variant="secondary" onClick={player.previousPage}>
							Previous
						</PreviousButton>
						<NextButton variant="primary" onClick={player.nextPage} isDisabled={!player.isNextPageEnabled}>
							Continue
						</NextButton>
						<SkipChapterButton variant="secondary" onClick={player.skipChapter}>
							Skip chapter
						</SkipChapterButton>
					</Buttons>
				</PageContent>
			) : player.current.page.type === "text" ? (
				<PageContent>
					<PageImage image={player.current.page.image} />
					<PageTitle>{player.current.page.title}</PageTitle>
					{player.current.page.htmlContent !== null && (
						<PageHtmlText dangerouslySetInnerHTML={{ __html: player.current.page.htmlContent }} />
					)}
					<Attachments links={player.current.page.links} attachments={player.current.page.attachments} />
					<Buttons>
						<PreviousButton variant="secondary" onClick={player.previousPage}>
							Previous
						</PreviousButton>
						<NextButton variant="primary" onClick={player.nextPage} isDisabled={!player.isNextPageEnabled}>
							Continue
						</NextButton>
						<SkipChapterButton variant="secondary" onClick={player.skipChapter}>
							Skip chapter
						</SkipChapterButton>
					</Buttons>
				</PageContent>
			) : (
				exhaustiveGuard(player.current.page)
			)}
			<Resources resources={player.course.resources} />
			{player.course.commentsEnabled && (
				<Comments>
					<Subtitle>Comments</Subtitle>
					<CourseComments
						courseId={Number(courseId)}
						commentsUnderReview={player.course.commentsUnderReview}
						pageId={player.current?.status === "unlocked" ? player.current.page.id : null}
						reloadCourse={player.reloadCourse}
					/>
				</Comments>
			)}
			<ChaptersList
				courseId={player.course.id}
				currentPageId={player.current?.status === "unlocked" ? player.current.page.id : null}
				firstUncompletedPageId={player.course.firstUncompletedPage?.id ?? null}
				isFreeNavigation={player.course.isFreeNavigation}
				setPage={player.setPageById}
			/>
		</StyledLayout>
	)
}

type CustomPageProps = {
	page: Extract<DetailedCoursePage, { type: "custom" }>
	isNextPageEnabled: boolean
	onPreviousPage(): void
	onNextPage(): void
	onSkipChapter(): void
}
const CustomPage: FC<CustomPageProps> = ({ page, isNextPageEnabled, onPreviousPage, onNextPage, onSkipChapter }) => {
	const [editorElement, setEditorElement] = useState<HTMLDivElement | null>(null)

	useEditorJsDisplay(editorElement, page.editorjsContent)

	return (
		<PageContent>
			<PageTitle>{page.title}</PageTitle>
			<PageEditorJs ref={setEditorElement} />
			<Attachments links={page.links} attachments={page.attachments} />
			<Buttons>
				<PreviousButton variant="secondary" onClick={onPreviousPage}>
					Previous
				</PreviousButton>
				<NextButton variant="primary" onClick={onNextPage} isDisabled={!isNextPageEnabled}>
					Continue
				</NextButton>
				<SkipChapterButton variant="secondary" onClick={onSkipChapter}>
					Skip chapter
				</SkipChapterButton>
			</Buttons>
		</PageContent>
	)
}

const PageImage: FC<{ image: { contain: boolean; filePath: string } | null }> = ({ image }) => {
	if (image === null) return null

	if (image.contain) {
		return <ContainImage src={image.filePath} />
	}
	return (
		<>
			<DesktopCoverImageContainer width={{ unit: "percent", value: 100 }} aspectRatio={16 / 4}>
				<CoverImage src={image.filePath} />
			</DesktopCoverImageContainer>
			<MobileCoverImageContainer width={{ unit: "percent", value: 100 }} aspectRatio={16 / 9}>
				<CoverImage src={image.filePath} />
			</MobileCoverImageContainer>
		</>
	)
}

const StyledLayout = styled(Layout)`
	display: flex;
	flex-direction: column;
	gap: 32px;
	padding-left: 16px;
	padding-right: 16px;

	@media (min-width: 1000px) {
		display: grid;
		grid-template-areas: "content chapters" "content resources" "comments resources";
		grid-template-columns: 2fr 1fr;
		grid-template-rows: repeat(3, min-content);
	}
`

const PageContent = styled.div`
	grid-area: content;
	flex: 1 0 0;
	display: flex;
	flex-direction: column;
`

const DesktopCoverImageContainer = styled(AspectRatio)`
	margin-bottom: 32px;

	@media (max-width: 999px) {
		display: none;
	}
`

const MobileCoverImageContainer = styled(AspectRatio)`
	margin-bottom: 32px;

	@media (min-width: 1000px) {
		display: none;
	}
`

const StyledVideoPlayer = styled(VideoPlayer)`
	margin-bottom: 32px;
`

const EmbeddedVideoContainer = styled(AspectRatio).attrs({
	width: { unit: "percent", value: 100 },
	aspectRatio: 16 / 9,
})`
	margin-bottom: 32px;
`

const CoverImage = styled.img.attrs({ alt: "Page illustration" })`
	object-fit: cover;
	border-radius: 8px;
`

const ContainImage = styled.img.attrs({ alt: "Page illustration" })`
	width: 100%;
	border-radius: 8px;
	margin-bottom: 32px;
`

const PageTitle = styled.h2`
	font-weight: 500;
	font-size: 24px;
	color: #151d48;
	margin-bottom: 8px;
`

const CourseCompletedTitle = styled(PageTitle)`
	margin-bottom: 32px;
`

const PageHtmlText = styled.div`
	font-size: 16px;
	color: ${lightTextColor};
	margin-bottom: 32px;
	display: flex;
	flex-direction: column;

	p,
	ul,
	ol {
		&:not(:last-child) {
			margin-bottom: 16px;
		}
	}

	h2 {
		color: black;
		font-size: 20px;

		&:not(:last-child) {
			margin-bottom: 4px;
		}
	}
`

const PageEditorJs = styled.div`
	max-width: 650px;
	margin-bottom: 32px;
`

const PageText = styled.div`
	font-size: 16px;
	color: ${lightTextColor};
	margin-bottom: 32px;
`

const DripDate = styled.time`
	text-decoration: underline dotted;
`

const Answers = styled.div`
	margin-bottom: 32px;
`

const Buttons = styled.div`
	display: grid;
	grid-template-areas: "previous next" "skipChapter skipChapter";
	gap: 16px;

	${mediaQueries.tablet} {
		grid-template-areas: "previous next skipChapter";
	}
`

const StyledButton = styled(Button)`
	min-width: 150px;

	@media (max-width: 999px) {
		min-width: 0;
		flex: 1;
	}
`

const PreviousButton = styled(StyledButton).attrs({ variant: "secondary" })`
	grid-area: previous;
`

const NextButton = styled(StyledButton).attrs({ variant: "primary" })`
	grid-area: next;
`

const SkipChapterButton = styled(StyledButton).attrs({ variant: "secondary" })`
	grid-area: skipChapter;
`

const Comments = styled.div`
	grid-area: comments;
`

const Subtitle = styled.h2`
	font-weight: 500;
	font-size: 24px;
	color: #151d48;
	margin-bottom: 24px;
`

export default CoursePlayerPage
