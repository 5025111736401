import { useState } from "react"
import snarkdown from "snarkdown"

import { useAlert } from "~/contexts/AlertContext"
import { usePlatform } from "~/contexts/PlatformContext"
import { SubmitButton } from "~/themes/school/components/Button"
import InputField from "~/themes/school/components/InputField"
import { useTranslation } from "~/translations"
import trpc from "~/utilities/trpc"

import Layout, { Text } from "./Layout"

const ResetPasswordPage: React.FC = () => {
	const alert = useAlert()
	const platformId = usePlatform().platform.id
	const t = useTranslation()

	const [email, setEmail] = useState("")
	const [isEmailSent, setEmailSent] = useState(false)
	const [isSubmitting, setSubmitting] = useState(false)

	const resetPassword = async () => {
		setSubmitting(true)
		try {
			const { status } = await trpc.user.resetStudentPassword.mutate({ email, platformId })
			if (status !== "success") {
				await alert.show(t("user.resetPassword.invalidEmail"), t("user.resetPassword.invalidEmailMessage"))
				return
			}

			setEmailSent(true)
		} finally {
			setSubmitting(false)
		}
	}

	return (
		<Layout title={t("user.resetPassword.title")} onSubmit={resetPassword}>
			{isEmailSent ? (
				<Text
					dangerouslySetInnerHTML={{
						__html: snarkdown(t("user.resetPassword.emailSent", { email: `**${email.trim()}**` })),
					}}
				/>
			) : (
				<>
					<Text>{t("user.resetPassword.message")}</Text>

					<InputField inputType="email" label={t("user.general.email")} value={email} onChange={setEmail} />

					<SubmitButton variant="primary" isLoading={isSubmitting}>
						{t("user.resetPassword.title")}
					</SubmitButton>
				</>
			)}
		</Layout>
	)
}

export default ResetPasswordPage
