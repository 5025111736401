import { forwardRef, type ReactNode } from "react"
import { Link } from "react-router"
import styled, { css } from "styled-components"

import { usePlatform } from "~/contexts/PlatformContext"
import LoadingIndicator from "~/themes/school/components/LoadingIndicator"
import { backgroundColor } from "~/themes/school/styles"
import { isColorDark } from "~/utilities/color"

type ButtonVariant = "none" | "link" | "primary" | "secondary" | "danger"

interface ButtonProps {
	variant?: ButtonVariant
	onClick?: string | (() => void)
	newTab?: boolean
	isLoading?: boolean
	isDisabled?: boolean
	replaceOnLoading?: boolean
	className?: string
	children?: ReactNode
}

interface SubmitButtonProps {
	variant?: ButtonVariant
	isLoading?: boolean
	isDisabled?: boolean
	replaceOnLoading?: boolean
	className?: string
	children?: ReactNode
}

const Button = forwardRef<HTMLButtonElement & HTMLAnchorElement & HTMLDivElement, ButtonProps>(
	({ variant = "none", onClick, newTab, isLoading, isDisabled, replaceOnLoading, className, children }, ref) => {
		const apiVersion = usePlatform().apiVersion

		if (isDisabled || isLoading) {
			return (
				<Container as="button" ref={ref} type="button" $variant={variant} $isDisabled className={className}>
					{isLoading && replaceOnLoading ? (
						<StyledLoadingIndicator />
					) : (
						<>
							{isLoading && <InlineLoadingIndicator />}
							{children}
						</>
					)}
				</Container>
			)
		}

		const newTabProps = { target: "_blank", rel: "noreferrer noopener" }
		if (onClick === undefined) {
			return (
				<Container ref={ref} $variant={variant} className={className}>
					{children}
				</Container>
			)
		} else if (typeof onClick === "function")
			return (
				<Container
					as="button"
					ref={ref}
					type="button"
					onClick={onClick}
					$variant={variant}
					className={className}
				>
					{children}
				</Container>
			)
		else if (onClick.startsWith("/"))
			return (
				<Container
					as={Link}
					reloadDocument={apiVersion !== null && apiVersion !== CONFIG.apiVersion}
					ref={ref}
					to={onClick}
					$variant={variant}
					className={className}
					{...(newTab ? newTabProps : {})}
				>
					{children}
				</Container>
			)
		else
			return (
				<Container
					as="a"
					ref={ref}
					href={onClick}
					$variant={variant}
					className={className}
					{...(newTab ? newTabProps : {})}
				>
					{children}
				</Container>
			)
	},
)

export const SubmitButton: React.FC<SubmitButtonProps> = ({
	variant = "none",
	isLoading,
	isDisabled,
	replaceOnLoading,
	className,
	children,
}) => {
	if (isDisabled || isLoading) {
		return (
			<Container $variant={variant} $isDisabled className={className}>
				{isLoading && replaceOnLoading ? (
					<StyledLoadingIndicator />
				) : (
					<>
						{isLoading && <InlineLoadingIndicator />}
						{children}
					</>
				)}
			</Container>
		)
	}

	return (
		<Container as="button" type="submit" $variant={variant} className={className}>
			{children}
		</Container>
	)
}

const disabledStyles = css`
	color: #454d66;
	background-color: ${backgroundColor};
`

const Container = styled.div<{ $variant: ButtonVariant; $isDisabled?: boolean }>`
	display: block;
	appearance: none;
	background-color: transparent;
	font-family: inherit;
	text-decoration: none;
	border: none;
	font-weight: normal;
	color: black;
	text-align: left;
	margin: 0;
	padding: 0;

	${props =>
		props.onClick !== undefined &&
		css`
			cursor: pointer;
		`}

	${props =>
		props.$variant === "link" &&
		css`
			display: inline-block;
			color: ${props => props.theme.primaryColor};
		`}

	${props =>
		props.$variant === "primary" &&
		css`
			color: ${isColorDark(props.theme.primaryColor) ? "white" : "black"};
			font-size: 14px;
			background-color: ${props => props.theme.primaryColor};
			padding: 8px 42px;
			border-radius: 10px;
			text-align: center;
			display: flex;
			justify-content: center;
			align-items: center;
			gap: 16px;

			${props.$isDisabled &&
			css`
				&,
				&:hover,
				&:active {
					${disabledStyles}
				}
			`}
		`}

	${props =>
		props.$variant === "secondary" &&
		css`
			color: white;
			font-size: 14px;
			background-color: #454d66;
			padding: 8px 42px;
			border-radius: 10px;
			text-align: center;
			display: flex;
			justify-content: center;
			align-items: center;
			gap: 16px;

			${props.$isDisabled &&
			css`
				&,
				&:hover,
				&:active {
					${disabledStyles}
				}
			`}
		`}

		${props =>
		props.$variant === "danger" &&
		css`
			color: ${isColorDark(props.theme.primaryColor) ? "white" : "black"};
			font-size: 14px;
			background-color: red;
			padding: 8px 42px;
			border-radius: 10px;
			text-align: center;
			display: flex;
			justify-content: center;
			align-items: center;
			gap: 16px;

			${props.$isDisabled &&
			css`
				&,
				&:hover,
				&:active {
					${disabledStyles}
				}
			`}
		`}
`

const InlineLoadingIndicator = styled(LoadingIndicator)`
	width: 12px;
	height: 12px;
`

const StyledLoadingIndicator = styled(LoadingIndicator)`
	width: 24px;
	height: 24px;
`

export default Button
