import { type FC } from "react"
import Markdown from "react-markdown"
import remarkGfm from "remark-gfm"
import styled from "styled-components"

import { useCookiePolicy } from "~/api/cookiePolicy"
import Layout from "~/themes/school/components/Layout"
import { PageTitle } from "~/themes/school/components/PageHeader"
import { fonts } from "~/themes/school/styles"
import { useTranslation } from "~/translations"

const CookiePolicyPage: FC = () => {
	const content = useCookiePolicy()
	const t = useTranslation()

	return (
		<Layout>
			<PageTitle>{t("policy.cookiePolicy")}</PageTitle>
			<Content>
				<Markdown remarkPlugins={[remarkGfm]}>{content}</Markdown>
			</Content>
		</Layout>
	)
}

const Content = styled.div`
	background-color: white;
	padding: 42px;

	h2 {
		font-family: ${fonts.quicksand};
		font-size: 16px;
		font-weight: bold;
		margin-bottom: 8px;
	}

	p {
		font-size: 14px;
		margin-bottom: 16px;
	}

	ul,
	ol {
		margin: 0 0 16px 16px;
	}

	table {
		width: 100%;
		border-collapse: collapse;
		border: 1px solid #ddd;
		margin-bottom: 16px;
	}

	table th,
	table td {
		padding: 8px;
	}
`

export default CookiePolicyPage
