import { useState } from "react"

import { useAlert } from "~/contexts/AlertContext"
import { usePlatform } from "~/contexts/PlatformContext"
import { SubmitButton } from "~/themes/original/components/Button"
import InputField from "~/themes/original/components/InputField"
import trpc from "~/utilities/trpc"

import Layout, { Title, Description } from "./Layout"

const ResetPasswordPage: React.FC = () => {
	const alert = useAlert()
	const platformId = usePlatform().platform.id

	const [email, setEmail] = useState("")
	const [isEmailSent, setEmailSent] = useState(false)
	const [isSubmitting, setSubmitting] = useState(false)

	const resetPassword = async () => {
		setSubmitting(true)
		try {
			const { status } = await trpc.user.resetStudentPassword.mutate({ email, platformId })
			if (status !== "success") {
				await alert.show("Invalid email", "That email address isn't registered with us.")
				return
			}

			setEmailSent(true)
		} finally {
			setSubmitting(false)
		}
	}

	return (
		<Layout onSubmit={resetPassword}>
			<Title>Reset password</Title>

			{isEmailSent ? (
				<Description>
					We've sent an email to <strong>{email.trim()}</strong> with a link to reset your password
				</Description>
			) : (
				<>
					<Description>Please enter the email address of your account</Description>

					<InputField inputType="email" label="Email address" value={email} onChange={setEmail} />

					<SubmitButton variant="primary" isLoading={isSubmitting}>
						Reset password
					</SubmitButton>
				</>
			)}
		</Layout>
	)
}

export default ResetPasswordPage
