import { type FC, useEffect } from "react"

function setElement(key: string, value: string, content: string): { cleanup(): void } {
	const element = document.querySelector(`meta[${key}="${value}"]`)
	if (element) {
		const originalValue = element.getAttribute("content") ?? ""
		element.setAttribute("content", content)
		return { cleanup: () => element.setAttribute("content", originalValue) }
	}
	const newElement = document.createElement("meta")
	newElement.setAttribute(key, value)
	newElement.setAttribute("content", content)
	document.head.appendChild(newElement)
	return { cleanup: () => newElement.remove() }
}

export type MetadataProps = { title?: string; description?: string; imageUrl?: string }
const Metadata: FC<MetadataProps> = ({ title, description, imageUrl }) => {
	useEffect(() => {
		const originalTitle = document.title
		if (title) {
			document.title = title
		}

		const ogTitleElement = title ? setElement("property", "og:title", title) : null
		const descriptionElement = description ? setElement("name", "description", description) : null
		const ogDescriptionElement = description ? setElement("property", "og:description", description) : null
		const ogImageElement = imageUrl ? setElement("property", "og:image", imageUrl) : null

		return () => {
			if (title) {
				document.title = originalTitle
			}
			ogTitleElement?.cleanup()
			descriptionElement?.cleanup()
			ogDescriptionElement?.cleanup()
			ogImageElement?.cleanup()
		}
	}, [title, description, imageUrl])

	return null
}

export default Metadata
