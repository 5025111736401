import { type FC } from "react"
import { Navigate } from "react-router"
import styled from "styled-components"

import CommunityProfileAvatar from "@forento/shared/components/CommunityProfileAvatar"
import { toCountFormat } from "@forento/shared/utilities/number"

import { usePlatform } from "~/contexts/PlatformContext"
import Layout from "~/themes/school/components/Layout"
import { PageTitle } from "~/themes/school/components/PageHeader"
import PartialLoadingPage from "~/themes/school/components/PartialLoadingPage"
import Table from "~/themes/school/components/Table"
import { useTranslation } from "~/translations"
import routes from "~/utilities/routes"
import { query } from "~/utilities/trpc"

const LeaderboardPage: FC = () => {
	const platform = usePlatform().platform
	const t = useTranslation()

	const { data: profiles, error } = query.community.listLeaderboard.useQuery()

	if (!platform.community?.isGamificationEnabled) {
		return <Navigate to={routes.community.index()} />
	}

	return (
		<Layout>
			<PageTitle>{t("community.leaderboard.title")}</PageTitle>
			<Container>
				{error ? (
					<p>{t("failedToLoad")}</p>
				) : profiles === undefined ? (
					<PartialLoadingPage />
				) : (
					<Table
						headers={[
							{ label: "#", width: 75 },
							t("community.leaderboard.name"),
							t("community.leaderboard.level"),
							t("community.leaderboard.totalPoints"),
						]}
						data={profiles.map((profile, index) => ({
							id: profile.id,
							columns: [
								index + 1,
								<>
									<CommunityProfileAvatar profile={profile} />
									<div>
										{profile.firstName} {profile.lastName}
									</div>
								</>,
								profile.gamification
									? (profile.gamification.currentLevel?.label ?? t("community.leaderboard.noLevel"))
									: null,
								profile.gamification ? toCountFormat(profile.gamification.points) : null,
							],
						}))}
					/>
				)}
			</Container>
		</Layout>
	)
}

const Container = styled.div`
	max-width: 1400px;
	background-color: #ffffff;
	box-shadow: 0px 4px 30px 0px rgba(223, 232, 255, 0.25);
	padding: 24px;
`

export default LeaderboardPage
