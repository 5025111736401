import { type FC, type ComponentType } from "react"
import styled, { css } from "styled-components"
import tinycolor from "tinycolor2"

import AspectRatio from "@forento/shared/components/AspectRatio"

import Button from "~/themes/original/components/Button"
import { lightTextColor } from "~/utilities/styles"

export const Cards = styled.div`
	display: flex;
	gap: 32px;
	flex-wrap: wrap;
`

type StickerType = { text: string; color?: "orange" | "blue" | "green" }
type Props = {
	title: string
	description?: string
	footer?: { text: string; isBold?: boolean } | { text: string; isBold?: boolean }[]
	onClick?: string
} & (
	| {
			thumbnailFilePath?: string | null
			sticker?: StickerType | StickerType[]
			progress?: number
			icon?: never
	  }
	| { icon?: ComponentType; thumbnailFilePath?: never; sticker?: never; progress?: never }
)
const Card: FC<Props> = ({ title, description, footer, onClick, ...rest }) => {
	const stickers = rest.sticker ? (Array.isArray(rest.sticker) ? rest.sticker : [rest.sticker]) : []
	const footerItems = footer ? (Array.isArray(footer) ? footer : [footer]) : []

	const content = (
		<>
			{rest.thumbnailFilePath !== undefined ? (
				<ThumbnailAspectRatio aspectRatio={16 / 12} width={{ value: 100, unit: "percent" }}>
					<ThumbnailContainer>
						{rest.thumbnailFilePath && <Thumbnail src={rest.thumbnailFilePath} />}
						{stickers.length > 0 && (
							<Stickers>
								{stickers.map((sticker, index) => (
									<Sticker key={index} $color={sticker.color ?? null}>
										{sticker.text}
									</Sticker>
								))}
							</Stickers>
						)}
						{rest.progress !== undefined && (
							<ProgressContainer>
								<Progress style={{ width: `${rest.progress * 100}%` }} />
							</ProgressContainer>
						)}
					</ThumbnailContainer>
				</ThumbnailAspectRatio>
			) : rest.icon ? (
				<IconAspectRatio aspectRatio={16 / 12} width={{ value: 50, unit: "percent" }}>
					<rest.icon />
				</IconAspectRatio>
			) : null}
			<Details>
				<Title>{title}</Title>
				{description && <Description>{description}</Description>}
			</Details>
			{footerItems.length > 0 && (
				<>
					<Divider />
					{footerItems.length === 1 ? (
						<FooterItem $isBold={footerItems[0].isBold} $isSole>
							{footerItems[0].text}
						</FooterItem>
					) : (
						<Footer>
							{footerItems.map((item, index) => (
								<FooterItem key={index} $isBold={item.isBold}>
									{item.text}
								</FooterItem>
							))}
						</Footer>
					)}
				</>
			)}
		</>
	)

	if (onClick) {
		return (
			<Container as={Button} onClick={onClick}>
				{content}
			</Container>
		)
	} else {
		return <Container>{content}</Container>
	}
}

const Container = styled.div`
	flex: 0 1 420px;
	background-color: #ffffff;
	border-radius: 8px;
	padding: 22px;
	display: flex;
	flex-direction: column;
`

const ThumbnailAspectRatio = styled(AspectRatio)`
	background-color: #999;
	border-radius: 8px;
	overflow: hidden;
	margin-bottom: 32px;
`

const ThumbnailContainer = styled.div`
	position: relative;
`

const Thumbnail = styled.img.attrs({ alt: "Thumbnail" })`
	width: 100%;
	height: 100%;
	object-fit: cover;
`

const IconAspectRatio = styled(AspectRatio)`
	align-self: center;
	color: ${props => props.theme.primaryColor};
	margin-bottom: 32px;
`

const Stickers = styled.div`
	position: absolute;
	top: 22px;
	right: 22px;
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	gap: 8px;
`

const Sticker = styled.div<{ $color: "orange" | "blue" | "green" | null }>`
	color: white;
	padding: 8px 12px;
	border-radius: 8px;
	background-color: ${({ $color }) =>
		$color === "orange"
			? "#FFA412"
			: $color === "blue"
				? "#2F80ED"
				: $color === "green"
					? "#50D789"
					: "transparent"};
`

const ProgressContainer = styled.div`
	position: absolute;
	left: 12px;
	right: 12px;
	bottom: 12px;
	height: 8px;
	border-radius: 4px;
	overflow: hidden;
	background-color: ${props => tinycolor(props.theme.primaryColor).lighten(20).toString()};
`

const Progress = styled.div`
	height: 100%;
	background-color: ${props => props.theme.primaryColor};
	border-radius: 4px;
`

const Details = styled.div`
	flex: 1;
	display: flex;
	flex-direction: column;
	gap: 16px;

	&:not(:last-child) {
		margin-bottom: 32px;
	}
`

const Title = styled.h1`
	font-weight: 600;
	font-size: 16px;
	color: #151d48;
`

const Description = styled.p`
	flex: 1;
	color: ${lightTextColor};
	font-size: 16px;
`

const Divider = styled.hr`
	border: none;
	border-bottom: 1px solid #e8e8ed;
	margin-bottom: 22px;
`

const Footer = styled.div`
	display: flex;
	justify-content: space-between;
	gap: 16px;
`

const FooterItem = styled.div<{ $isBold?: boolean; $isSole?: boolean }>`
	font-size: 16px;
	color: ${lightTextColor};

	${props =>
		props.$isBold &&
		css`
			font-size: 20px;
			font-weight: 600;
			color: #ffa412;
		`}

	${props =>
		props.$isSole &&
		css`
			align-self: flex-end;
		`}
`

export default Card
