import { type JSX, type FC } from "react"
import styled from "styled-components"

import AspectRatio from "../AspectRatio"
import ApiVideo from "./ApiVideo"

interface Props {
	video: { playableDate?: Date | null } & (
		| { host: "apiVideo"; apiVideoId: string }
		| { host: "bunny"; bunnyVideoId: string }
	)
	className?: string
}

const VideoPlayer: FC<Props> = ({ video, className }) => {
	const children: JSX.Element = (() => {
		if (video.playableDate === null) return <ProcessingContainer>Processing...</ProcessingContainer>

		switch (video.host) {
			case "apiVideo":
				return <ApiVideo apiVideoId={video.apiVideoId} />
			case "bunny":
				return (
					<BunnyVideo
						src={`/api/videos/iframe/bunny/${video.bunnyVideoId}`}
						loading="lazy"
						allow="accelerometer; gyroscope; encrypted-media; picture-in-picture;"
						allowFullScreen
					/>
				)
		}
	})()

	return (
		<AspectRatio width={{ unit: "percent", value: 100 }} aspectRatio={16 / 9} className={className}>
			{children}
		</AspectRatio>
	)
}

const ProcessingContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	font-size: 24px;
	font-weight: 600;
`

const BunnyVideo = styled.iframe`
	border: none;
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
`

export default VideoPlayer
