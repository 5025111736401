import { type FC } from "react"
import { Navigate } from "react-router"
import styled from "styled-components"

import { usePlatform } from "~/contexts/PlatformContext"
import Layout from "~/themes/original/components/Layout"
import PartialLoadingPage from "~/themes/original/components/PartialLoadingPage"
import routes from "~/utilities/routes"
import { query } from "~/utilities/trpc"

import Profile from "./Profile"

const LeaderboardPage: FC = () => {
	const platform = usePlatform().platform

	const { data: profiles, error } = query.community.listLeaderboard.useQuery()

	if (!platform.community?.isGamificationEnabled) {
		return <Navigate to={routes.community.index()} />
	}

	return (
		<Layout>
			{error ? (
				<p>Failed to load leaderboard.</p>
			) : profiles === undefined ? (
				<PartialLoadingPage />
			) : (
				<Container>
					{profiles.map((profile, index) => (
						<Profile key={profile.id} profile={profile} position={index + 1} />
					))}
				</Container>
			)}
		</Layout>
	)
}

const Container = styled.div`
	display: flex;
	flex-direction: column;
	gap: 16px;
`

export default LeaderboardPage
