import { type FC, lazy, Suspense, useState } from "react"
import { Navigate, useParams } from "react-router"
import styled from "styled-components"
import Values from "values.js"

import AspectRatio from "@forento/shared/components/AspectRatio"
import { parseNumber } from "@forento/shared/utilities/number"

import { PurchaseSuccessModalContextProvider } from "~/contexts/PurchaseSuccessModalContext"
import Button from "~/themes/original/components/Button"
import Layout from "~/themes/original/components/Layout"
import Modal, { ModalButtons, ModalTitle } from "~/themes/original/components/Modal"
import PartialLoadingPage from "~/themes/original/components/PartialLoadingPage"
import PurchaseItem from "~/themes/original/components/PurchaseItem"
import routes from "~/utilities/routes"
import { lightTextColor } from "~/utilities/styles"
import { query } from "~/utilities/trpc"

const DefaultThumbnail = lazy(() => import("./DefaultThumbnail"))

const SessionPage: FC = () => {
	const sessionId = parseNumber(useParams().sessionId!)

	const [isModalOpen, setModalOpen] = useState(false)

	const { data: session, error } = query.privateSession.getPersonalized.useQuery(sessionId ?? -1)

	if (session === null) return <Navigate to={routes.session.index()} />

	const redemotionInstructions = session?.status === "unlocked" ? session.redemptionInstructions : null
	const redemptionLink = session?.status === "unlocked" ? session.redemptionLink : null

	return (
		<Layout>
			<Modal isOpen={isModalOpen}>
				<ModalTitle>Redeem session</ModalTitle>
				<p>{redemotionInstructions}</p>
				<ModalButtons>
					{redemptionLink !== null ? (
						<>
							<Button variant="secondary" onClick={() => setModalOpen(false)}>
								Cancel
							</Button>
							<Button variant="primary" onClick={redemptionLink} newTab>
								Redeem
							</Button>
						</>
					) : (
						<Button variant="primary" onClick={() => setModalOpen(false)}>
							Close
						</Button>
					)}
				</ModalButtons>
			</Modal>
			{error ? (
				<p>Failed to load session.</p>
			) : session === undefined ? (
				<PartialLoadingPage />
			) : (
				<PurchaseSuccessModalContextProvider
					content={{
						type: "session",
						isUnlocked: session.status === "unlocked",
						payPalOrderId:
							session.status === "unlocked" &&
							session.unlockedCause === "purchased" &&
							session.order.type === "paypal"
								? session.order.id
								: null,
					}}
				>
					<Content>
						<Details>
							<Title>{session.title}</Title>
							{session.longDescription && (
								<Description dangerouslySetInnerHTML={{ __html: session.longDescription.html }} />
							)}
							{session.status === "paywall" ? (
								<PurchaseItem
									item={{ type: "session", id: session.id, price: session.price }}
									onPurchase={() => {}}
								/>
							) : session.status === "sign-in-required" ? (
								<>
									<CallToActionText>
										This downloadable is only available to signed in users.
									</CallToActionText>
									<ButtonsRow>
										<Button variant="primary" onClick={routes.user.signin()}>
											Sign in
										</Button>
										<Button variant="primary" onClick={routes.user.signup()}>
											Sign up
										</Button>
									</ButtonsRow>
								</>
							) : session.status === "membership-required" ? (
								<>
									<CallToActionText>This downloadable is only available to members.</CallToActionText>
									<ButtonsRow>
										<Button variant="primary" onClick={routes.membership.index()}>
											Memberships
										</Button>
									</ButtonsRow>
								</>
							) : session.status === "unlocked" ? (
								session.redemptionInstructions !== null ? (
									<Button variant="primary" onClick={() => setModalOpen(true)}>
										Redeem
									</Button>
								) : session.redemptionLink !== null ? (
									<Button variant="primary" onClick={session.redemptionLink} newTab>
										Redeem
									</Button>
								) : null
							) : null}
						</Details>
						<ThumbnailContainer>
							<AspectRatio width={{ value: 100, unit: "percent" }} aspectRatio={16 / 12}>
								{session.thumbnailFilePath !== null ? (
									<Thumbnail src={session.thumbnailFilePath} />
								) : (
									<Suspense>
										<DefaultThumbnail />
									</Suspense>
								)}
							</AspectRatio>
						</ThumbnailContainer>
					</Content>
				</PurchaseSuccessModalContextProvider>
			)}
		</Layout>
	)
}

const Content = styled.div`
	background-color: ${props => new Values(props.theme.primaryColor).tint(90).hexString()};
	border-radius: 16px;
	display: flex;
	padding: 42px;
	gap: ${42 * 2}px;
	margin-bottom: 42px;
`

const Details = styled.div`
	flex: 1 0 0;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	gap: 22px;
`

const Title = styled.h1`
	font-weight: 500;
	font-size: 24px;
	color: #151d48;
`

const Description = styled.div`
	font-size: 16px;
	color: ${lightTextColor};
`

const ThumbnailContainer = styled.div`
	flex: 1 0 0;
	border-radius: 8px;
	overflow: hidden;

	@media (max-width: 999px) {
		display: none;
	}
`

const Thumbnail = styled.img.attrs({ alt: "Session thumbnail" })`
	object-fit: cover;
`

const CallToActionText = styled.p`
	font-size: 16px;
	color: ${lightTextColor};
	font-style: italic;
`

const ButtonsRow = styled.div`
	display: flex;
	gap: 16px;
`

export default SessionPage
