import { usePlatform } from "~/contexts/PlatformContext"
import routes from "~/utilities/routes"

export default function useHomeRoute() {
	const platform = usePlatform().platform

	if (platform.content.courses) return routes.course.index()
	if (platform.content.videos) return routes.video.index()
	if (platform.content.downloadables) return routes.downloadable.index()
	if (platform.content.events) return routes.event.index()
	if (platform.content.sessions) return routes.session.index()
	if (platform.content.exams) return routes.exam.index()
	if (platform.content.memberships) return routes.membership.index()

	return routes.course.index()
}
