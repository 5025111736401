import { usePlatform } from "~/contexts/PlatformContext"

export function useCookiePolicy(): string {
	const platform = usePlatform().platform

	return `
Effective Date: 2025-02-14

## Introduction

Welcome to ${platform.name} ("Company," "we," "our," or "us"). This Cookie Policy explains how we use cookies and similar technologies on our website [${platform.domain.platform}](https://${platform.domain.platform}) ("Website"). By using our Website, you agree to our use of cookies in accordance with this policy.

## What Are Cookies?

Cookies are small text files stored on your device (computer, tablet, or mobile phone) when you visit a website. They help us enhance user experience, improve website functionality, and analyze website traffic.

## Types of Cookies We Use

We use the following types of cookies:

- **Essential Cookies**: These are necessary for the Website to function properly. They enable core functionalities such as security, network management, and accessibility.
- **Performance and Analytics Cookies**: These cookies help us understand how visitors interact with our Website by collecting and reporting information anonymously.

## Specific Cookies We Use

Below is a list of cookies we use on our Website:

| Cookie Name | Type | Purpose | Expiration |
| ------------- | ------ | --------- | ------------ |
| platform-cookie-option | Essential | Stores user cookie consent | 1 year |
| session | Essential | Maintains user session | 6 months |
| mtm_cookie_consent | Essential | Stores user consent for marketing cookies | 30 years |
| _pk_id | Analytics | Stores a unique visitor ID | 13 months |
| _pk_ses | Analytics | Session cookie temporarily stores data for the visit | 30 minutes |
| _pk_ref | Analytics | Stores attribution information (the referrer that brought the visitor to the website) | 6 months |
| _pk_testcookie | Analytics | Temporary cookie to check if a visitor's browser supports cookies (set in Internet Explorer only) | Temporary cookie that expires almost immediately after being set. |

## How We Use Cookies

We use cookies to:
- Ensure the proper functioning of our Website.
- Remember your preferences and settings.
- Analyze Website traffic and improve performance.
- Deliver targeted advertisements and measure their effectiveness.

## Managing Cookies

You can manage or delete cookies at any time by adjusting your browser settings. Most browsers allow you to:
- Block or delete cookies.
- Set preferences for how cookies are handled.
- Receive notifications when a website attempts to place a cookie on your device.

Please note that disabling cookies may impact the functionality of our Website.

## Third-Party Cookies

We may use third-party services (e.g., Google Analytics, advertising partners) that place cookies on your device. These cookies are governed by the respective third parties' privacy policies. We recommend reviewing their policies for more information.

## Changes to This Cookie Policy

We may update this Cookie Policy from time to time. Any changes will be posted on this page with an updated effective date. We encourage you to review this policy periodically.

## Contact Us

If you have any questions about this Cookie Policy, please contact us at:

${platform.name}

${platform.contactEmail}
    `
}
