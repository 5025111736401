import { type FC } from "react"
import styled from "styled-components"

import { usePlatform } from "~/contexts/PlatformContext"
import Button from "~/themes/school/components/Button"
import { lightTextColor } from "~/themes/school/styles"
import { useTranslation } from "~/translations"
import routes from "~/utilities/routes"

const Footer: FC = () => {
	const platform = usePlatform().platform
	const t = useTranslation()

	const navItems = [
		{ label: t("policy.termsOfService"), onClick: routes.policy.termsOfService() },
		{ label: t("policy.privacyPolicy"), onClick: routes.policy.privacy() },
		{ label: t("policy.cookiePolicy"), onClick: routes.policy.cookie() },
	]

	return (
		<Container>
			<Nav>
				<NavItem>
					© {new Date().getFullYear()} {platform.name}
				</NavItem>
				{navItems.map(item => (
					<NavItem key={item.label} as={Button} onClick={item.onClick}>
						{item.label}
					</NavItem>
				))}
			</Nav>
			{platform.hasWatermark && (
				<div>
					<Watermark newTab onClick="https://forento.io?s=3546">
						{t("general.watermark.title")}
					</Watermark>
					<WatermarkText>{t("general.watermark.text")}</WatermarkText>
				</div>
			)}
		</Container>
	)
}

const Container = styled.div`
	background: white;
	padding: 24px;
	display: flex;
	flex-direction: column;
	gap: 16px;

	@media (min-width: 1000px) {
		padding: 42px;
	}
`

const Nav = styled.div`
	display: flex;
	align-items: center;
	gap: 16px;

	@media (max-width: 449px) {
		flex-direction: column;
	}
`

const NavItem = styled.div`
	color: ${lightTextColor};
	font-size: 16px;
`

const Watermark = styled(Button)`
	font-weight: 600;
	font-size: 16px;
	margin-bottom: 4px;
`

const WatermarkText = styled.div`
	color: ${lightTextColor};
	font-size: 14px;
`

export default Footer
