import { type FC, Fragment } from "react"
import styled, { css } from "styled-components"

import Button from "~/themes/school/components/Button"
import { BreadcrumbSeparatorIcon } from "~/themes/school/components/Icon"
import { fonts } from "~/themes/school/styles"

export const PageTitle = styled.h1`
	font-family: ${fonts.quicksand};
	font-size: 20px;
	font-weight: bold;
	line-height: 1;
	margin-bottom: 32px;
`

export const PageBreadcrumb: FC<{ title: string; path: { title: string; link?: string }[] }> = ({ title, path }) => (
	<BreadcrumbContainer>
		{path.map(({ title, link }, index) => (
			<Fragment key={index}>
				{link !== undefined ? (
					<BreadcrumbItem as={Button} onClick={link}>
						{title}
					</BreadcrumbItem>
				) : (
					<BreadcrumbItem>{title}</BreadcrumbItem>
				)}
				<BreadcrumbSeparator />
			</Fragment>
		))}
		<BreadcrumbTitle>{title}</BreadcrumbTitle>
	</BreadcrumbContainer>
)

const BreadcrumbContainer = styled.div`
	display: flex;
	align-items: center;
	gap: 8px;
	margin-bottom: 32px;
`

const BreadcrumbItem = styled.div`
	font-family: ${fonts.quicksand};
	font-size: 20px;
	font-weight: bold;
	color: #a1a7b4;
	line-height: 1;
`

const BreadcrumbSeparator = styled(BreadcrumbSeparatorIcon)`
	width: 20px;
	height: 20px;
	color: #a1a7b4;
`

const BreadcrumbTitle = styled.h1`
	font-family: ${fonts.quicksand};
	font-size: 20px;
	font-weight: bold;
	line-height: 1;
`

const PageHeader = styled.div<{ $matchPageWidth?: boolean }>`
	display: flex;
	justify-content: space-between;
	gap: 16px;
	align-items: center;
	margin-bottom: 32px;
	flex-wrap: wrap;

	${props =>
		props.$matchPageWidth &&
		css`
			max-width: 1400px;
		`}

	> * {
		margin: 0;
	}
`

export const PageHeaderGroup = styled.div`
	display: flex;
	align-items: center;
	gap: 16px;
`

export default PageHeader
