export const supportedFileExtensions = {
	image: ["PNG", "JPG", "JPEG", "GIF", "SVG"],
	spreadsheet: ["CSV", "XLS", "XLSX"],
}

export function getFileNameFromPath(filePath: string): string | null {
	return filePath.split("/").pop()?.toLowerCase() ?? null
}

export function getExtensionByFileName(fileName: string): string | null {
	return fileName.split(".").pop()?.toLowerCase() ?? null
}

export async function getDataUrlByFile(file: File) {
	return new Promise<string>((resolve, reject) => {
		const reader = new FileReader()
		reader.onload = () => {
			const result = reader.result as string
			const zipMimeTypes = ["application/x-zip-compressed", "application/zip-compressed", "application/x-zip"]

			for (const mimeType of zipMimeTypes) {
				if (result.startsWith(`data:${mimeType};base64`)) {
					return resolve(result.replace(`data:${mimeType};base64`, "data:application/zip;base64"))
				}
			}

			resolve(result)
		}
		reader.onerror = () => reject(reader.error)
		reader.readAsDataURL(file)
	})
}
