import Fuse from "fuse.js"
import { type FC, useEffect, useMemo, useState } from "react"
import styled from "styled-components"
import { useDebounce } from "use-debounce"

import { useTranslation } from "~/translations"

import Button from "./Button"
import InputField from "./InputField"

type Props<T extends string> = {
	keys: T[]
	items: ({ id: number } & Record<T, string>)[]
	onItemsToShowChange(items: number[] | undefined): void
}
const ContentSearch = <T extends string>({ keys, items, onItemsToShowChange }: Props<T>) => {
	const t = useTranslation()

	const [value, setValue] = useState("")

	const [debouncedValue] = useDebounce(value, 500)

	const searcher = useMemo(() => new Fuse(items, { keys, includeScore: true }), [items, keys])

	useEffect(() => {
		if (debouncedValue.trim().length === 0) {
			onItemsToShowChange(undefined)
		} else {
			onItemsToShowChange(
				searcher
					.search(debouncedValue)
					.sort((a, b) => (b.score ?? 0) - (a.score ?? 0))
					.map(item => items[item.refIndex].id),
			)
		}
	}, [items, onItemsToShowChange, searcher, debouncedValue])

	return (
		<Container>
			<InputField placeholder={t("general.search")} value={value} onChange={setValue} />
			{value.trim().length > 0 && (
				<Button variant="primary" onClick={() => setValue("")}>
					{t("clearFields")}
				</Button>
			)}
		</Container>
	)
}

const Container = styled.div`
	width: 100%;
	max-width: 500px;
	display: flex;
	flex-direction: row;
	gap: 8px;
	flex-wrap: wrap;
	justify-content: center;
`

type TitleSearchProps = {
	items: { id: number; title: string }[]
	onItemsToShowChange(items: number[] | undefined): void
}
export const TitleSearch: FC<TitleSearchProps> = ({ items, onItemsToShowChange }) => {
	const keys = useMemo(() => ["title" as const], [])

	return <ContentSearch keys={keys} items={items} onItemsToShowChange={onItemsToShowChange} />
}

export default ContentSearch
