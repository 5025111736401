import { type FC, type ReactNode, useState } from "react"
import styled, { createGlobalStyle } from "styled-components"

import Metadata, { type MetadataProps } from "~/components/Metadata"
import { usePlatform } from "~/contexts/PlatformContext"
import CookieConsent from "~/themes/original/components/CookieConsent"
import { BackIcon, CloseIcon, MenuIcon } from "~/themes/original/components/Icon"

import Footer from "./Footer"
import Header from "./Header"
import MobileHeader from "./MobileHeader"
import NavigationMenu from "./NavigationMenu"

const GlobalStyle = createGlobalStyle`
	* {
		font-family: "Poppins", sans-serif;
	}
`

type Props = {
	metadata?: MetadataProps
	onBackButtonClick?: string | (() => void)
	className?: string
	children?: ReactNode
}
const Layout: FC<Props> = ({ metadata, onBackButtonClick, className, children }) => {
	const { platform } = usePlatform()

	const [isMenuOpen, setMenuOpen] = useState(false)

	return (
		<Wrapper>
			<Metadata
				{...(metadata ?? {
					title: platform.title ?? platform.name,
					description: platform.description ?? undefined,
					imageUrl: platform.thumbnailFilePath ?? undefined,
				})}
			/>
			<link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700" />
			<GlobalStyle />
			<CookieConsent />
			<Container>
				<Header />
				<MobileHeader
					leftAction={
						onBackButtonClick === undefined
							? {
									icon: isMenuOpen ? CloseIcon : MenuIcon,
									onClick: () => setMenuOpen(current => !current),
								}
							: { icon: BackIcon, onClick: onBackButtonClick }
					}
				/>
				<Content className={className}>{children}</Content>
				<Footer />
				<NavigationMenu isOpen={isMenuOpen} />
			</Container>
		</Wrapper>
	)
}

const Wrapper = styled.div`
	width: 100%;
	background-color: #f7f7f7;
	display: flex;
	justify-content: center;
`

const Container = styled.div`
	width: 100%;
	max-width: 1400px;
	min-height: 100vh;
	position: relative;
	display: flex;
	flex-direction: column;
`

const Content = styled.div`
	flex-grow: 1;
	padding: 24px 0;
	display: flex;
	flex-direction: column;

	@media (min-width: 1370px) {
		padding: 0 42px 36px;
	}
`

export default Layout
