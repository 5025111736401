import { type FC, useEffect, useState } from "react"
import styled, { css, keyframes } from "styled-components"

import CommunityProfileAvatar from "@forento/shared/components/CommunityProfileAvatar"
import Tooltip from "@forento/shared/components/Tooltip"

import { useUser } from "~/contexts/UserContext"
import { MessageIcon, ProfileIcon } from "~/themes/original/components/Icon"
import PartialLoadingPage from "~/themes/original/components/PartialLoadingPage"
import routes from "~/utilities/routes"
import { lightTextColor } from "~/utilities/styles"
import { query } from "~/utilities/trpc"

import Button from "./Button"

const hoverMargin = 16

type Props = { profileId: number; targetElement: HTMLElement | null }
const ProfilePopup: FC<Props> = ({ profileId, targetElement }) => {
	const user = useUser().user!

	const [isHovering, setHovering] = useState(false)
	const [state, setState] = useState<"idle" | "open" | "closed">("idle")
	const [position, setPosition] = useState<{
		x: number
		y: number
		xOffset: number
	}>({ x: 0, y: 0, xOffset: 0 })

	const { data: profile, error } = query.community.getProfile.useQuery(profileId, { enabled: state === "open" })

	useEffect(() => {
		if (targetElement === null) return

		const handleMouseEnter = () => {
			setPosition({
				x: targetElement.offsetLeft,
				y: targetElement.offsetTop,
				xOffset: targetElement.getBoundingClientRect().width + 16,
			})
			setHovering(true)
		}

		const handleMouseExit = () => {
			setHovering(false)
		}

		targetElement.addEventListener("mouseenter", handleMouseEnter)
		targetElement.addEventListener("mouseleave", handleMouseExit)

		return () => {
			targetElement.removeEventListener("mouseenter", handleMouseEnter)
			targetElement.removeEventListener("mouseleave", handleMouseExit)
		}
	}, [targetElement])

	useEffect(() => {
		if (isHovering) {
			const timeout = setTimeout(() => {
				setState("open")
			}, 200)
			return () => {
				clearTimeout(timeout)
			}
		}
	}, [isHovering])

	useEffect(() => {
		if (state === "closed") {
			const timeout = setTimeout(() => setState("idle"), 300)
			return () => {
				clearTimeout(timeout)
			}
		}
	}, [state])

	if (state === "idle") return null

	return (
		<Container $x={position.x} $y={position.y} onMouseLeave={() => setState("closed")}>
			<Popup $xOffset={position.xOffset} $state={state}>
				{error ? (
					<p>Failed to load profile.</p>
				) : profile === null ? null : profile === undefined ? (
					<PartialLoadingPage />
				) : (
					<>
						<Header>
							<CommunityProfileAvatar profile={profile} size={82} />
							<Details>
								<Name>
									{profile.firstName} {profile.lastName}
								</Name>
								{profile.gamification?.currentLevel != null && (
									<Level>
										Level {profile.gamification.currentLevel.tier} -{" "}
										{profile.gamification.currentLevel.label}
									</Level>
								)}
							</Details>
						</Header>
						<Stats>
							<Stat.Container>
								<Stat.Value>{profile.posts}</Stat.Value>
								<Stat.Label>Posts</Stat.Label>
							</Stat.Container>
							<Stat.Container>
								<Stat.Value>{profile.reactions}</Stat.Value>
								<Stat.Label>Reactions</Stat.Label>
							</Stat.Container>
							<Stat.Container>
								<Stat.Value>{profile.comments}</Stat.Value>
								<Stat.Label>Comments</Stat.Label>
							</Stat.Container>
						</Stats>
						<Divider />
						{profile.id !== user.communityProfileId && (
							<Actions>
								<Tooltip tooltip="View full profile">
									<Action onClick={routes.community.profile(profile.id)}>
										<ProfileIcon />
									</Action>
								</Tooltip>
								<Tooltip tooltip="Send message">
									<Action onClick={routes.community.conversation(profile.id)}>
										<MessageIcon />
									</Action>
								</Tooltip>
							</Actions>
						)}
					</>
				)}
			</Popup>
		</Container>
	)
}

const Container = styled.div<{ $x: number; $y: number }>`
	position: absolute;
	top: ${props => props.$y - hoverMargin}px;
	left: ${props => props.$x - hoverMargin}px;
`

const openAnimation = keyframes`
	from { transform: scale(0); }
	to { transform: scale(1); }
`

const closeAnimation = keyframes`
	from { transform: scale(1); }
	to { transform: scale(0);}
`

const Popup = styled.div<{ $xOffset: number; $state: "idle" | "open" | "closed" }>`
	transition: 0.1s transform;
	transform-origin: top left;
	margin-left: ${props => props.$xOffset + hoverMargin}px;
	margin-top: ${hoverMargin}px;
	background-color: white;
	box-shadow: 0 20px 40px -2px rgba(0, 0, 0, 0.16);
	border-radius: 8px;
	padding: 24px;
	display: flex;
	flex-direction: column;
	gap: 16px;

	${props =>
		props.$state === "open"
			? css`
					animation: ${openAnimation} 0.15s forwards;
				`
			: props.$state === "closed"
				? css`
						animation: ${closeAnimation} 0.15s forwards;
					`
				: ""}
`

const Header = styled.div`
	display: flex;
	gap: 16px;
`

const Details = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	gap: 4px;
`

const Name = styled.h1`
	font-size: 22px;
	font-weight: 600;
	color: #151d48;
`

const Level = styled.p`
	font-size: 14px;
	color: ${lightTextColor};
`

const Divider = styled.hr`
	width: 100%;
	border: none;
	border-bottom: 1px solid #dedede;
	margin: 8px 0;
`

const Actions = styled.div`
	display: flex;
	align-items: center;
	gap: 16px;
`

const Action = styled(Button)`
	width: 24px;
	height: 24px;
	color: ${lightTextColor};
`

const Stats = styled.div`
	width: 100%;
	display: flex;
	justify-content: space-between;
	gap: 8px;
`

const Stat = {
	Container: styled.div`
		flex: 1 0 0;
		display: flex;
		flex-direction: column;
		align-items: center;
	`,
	Value: styled.p`
		font-size: 22px;
		font-weight: bold;
		margin-bottom: 6px;
	`,
	Label: styled.p`
		font-size: 14px;
	`,
}

export default ProfilePopup
