import { type FC, type ReactNode, useLayoutEffect, useMemo, useState } from "react"
import { createPortal } from "react-dom"
import styled, { css, keyframes } from "styled-components"

import Form from "./Form"

type State = "idle" | "open" | "closed"

interface Props {
	isOpen: boolean
	width?: string | number
	onSubmit?(): void
	children: ReactNode
}

const Modal: FC<Props> = ({ isOpen, width, onSubmit, children }) => {
	const [state, setState] = useState<State>(isOpen ? "open" : "idle")

	useLayoutEffect(() => {
		if (!isOpen && state === "idle") return
		setState(isOpen ? "open" : "closed")
	}, [isOpen, state])

	const container = useMemo(() => {
		const element = document.createElement("div")
		document.body.appendChild(element)
		return element
	}, [])

	if (state === "idle") return null

	return createPortal(
		onSubmit !== undefined ? (
			<Container $state={state}>
				<Frame as={Form} $state={state} $width={width} onSubmit={onSubmit}>
					{children}
				</Frame>
			</Container>
		) : (
			<Container $state={state}>
				<Frame $state={state} $width={width}>
					{children}
				</Frame>
			</Container>
		),
		container,
	)
}

const Container = styled.div<{ $state: State }>`
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 1000;
	background-color: ${props => (props.$state === "open" ? "rgba(28, 28, 28, 0.6)" : "transparent")};
	backdrop-filter: ${props => (props.$state === "open" ? "blur(8px)" : "none")};
	pointer-events: ${props => (props.$state === "open" ? "all" : "none")};
	display: flex;
	justify-content: center;
	align-items: center;
	transition: 0.15s background-color;
`

const openAnimation = keyframes`
	from {
		transform: scale(0);
	}
	to {
		transform: scale(1);
	}
`

const closeAnimation = keyframes`
	from {
		transform: scale(1);
	}
	to {
		transform: scale(0);
	}
`

const Frame = styled.div<{ $state: State; $width?: string | number }>`
	${props =>
		props.$width !== undefined &&
		css`
			width: ${typeof props.$width === "number" ? `${props.$width}px` : props.$width};
		`}
	max-height: 95vh;
	overflow-y: auto;
	background-color: white;
	display: flex;
	flex-direction: column;
	align-items: stretch;
	transform: scale(0);
	${props =>
		props.$state === "open"
			? css`
					animation: ${openAnimation} 0.15s forwards;
				`
			: props.$state === "closed"
				? css`
						animation: ${closeAnimation} 0.15s forwards;
					`
				: ""}
`

export default Modal
