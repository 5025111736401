import { useState } from "react"
import styled from "styled-components"

import { useAlert } from "~/contexts/AlertContext"
import { useUser } from "~/contexts/UserContext"
import { SubmitButton } from "~/themes/original/components/Button"
import InputField from "~/themes/original/components/InputField"
import Layout from "~/themes/original/components/Layout"
import trpc from "~/utilities/trpc"

import DeleteAccount from "./DeleteAccount"
import Group from "./Group"
import Notifications from "./Notifications"
import ProfileAvatar from "./ProfileAvatar"

const SettingsPage: React.FC = () => {
	const user = useUser()
	const alert = useAlert()

	const [firstName, setFirstName] = useState(user.user!.firstName)
	const [lastName, setLastName] = useState(user.user!.lastName)
	const [currentPassword, setCurrentPassword] = useState("")
	const [newPassword, setNewPassword] = useState("")
	const [newPasswordConfirmation, setNewPasswordConfirmation] = useState("")

	const [isSubmittingPersonalInfo, setSubmittingPersonalInfo] = useState(false)
	const [isSubmittingChangePassword, setSubmittingChangePassword] = useState(false)

	const handleSavePersonalInfo = async () => {
		const fields = {
			firstName: firstName.trim(),
			lastName: lastName.trim(),
		}
		if (Object.values(fields).some(field => field.length === 0)) {
			await alert.show("Error", "Please fill in all fields.")
			return
		}
		setSubmittingPersonalInfo(true)

		try {
			await trpc.user.update.mutate(fields)
			await user.reload()
		} finally {
			setSubmittingPersonalInfo(false)
		}
	}

	const handleChangePassword = async () => {
		if (currentPassword.length === 0 || newPassword.length === 0) {
			await alert.show("Error", "Please fill in all fields.")
			return
		}

		if (newPassword !== newPasswordConfirmation) {
			await alert.show("Error", "Passwords don't match.")
			return
		}

		setSubmittingChangePassword(true)
		try {
			const { status } = await trpc.user.setPassword.mutate({ current: currentPassword, new: newPassword })
			if (status !== "success") {
				await alert.show("Error", "Incorrect current password.")
				return
			}
			await user.reload()
			setCurrentPassword("")
			setNewPassword("")
			setNewPasswordConfirmation("")
			await alert.show("Success", "Password changed successfully!")
		} catch (error) {
			console.error(error)
			await alert.show("Error", "Failed to change password. Please try again later.")
		} finally {
			setSubmittingChangePassword(false)
		}
	}

	return (
		<Layout>
			<Title>Settings</Title>

			<Groups>
				<Group title="Personal information" onSubmit={handleSavePersonalInfo}>
					<InputField label="Email address" value={user.user!.email} disabled />
					<InputField
						label="First name"
						value={firstName}
						onChange={setFirstName}
						autoComplete="given-name"
					/>
					<InputField label="Last name" value={lastName} onChange={setLastName} autoComplete="family-name" />
					<SubmitButton variant="primary" isLoading={isSubmittingPersonalInfo}>
						Save changes
					</SubmitButton>
				</Group>

				<Group title="Change your password" onSubmit={handleChangePassword}>
					<InputField
						inputType="password"
						label="Current password"
						value={currentPassword}
						onChange={setCurrentPassword}
						autoComplete="current-password"
					/>
					<InputField
						inputType="password"
						label="New password"
						value={newPassword}
						onChange={setNewPassword}
						autoComplete="new-password"
					/>
					<InputField
						inputType="password"
						label="Confirm new password"
						value={newPasswordConfirmation}
						onChange={setNewPasswordConfirmation}
						autoComplete="new-password"
					/>
					<SubmitButton variant="primary" isLoading={isSubmittingChangePassword}>
						Change password
					</SubmitButton>
				</Group>

				{!user.user!.isCreator && (
					<Group title="Notifications">
						<Notifications />
					</Group>
				)}

				{user.user!.communityProfileId !== null && (
					<Group title="Community avatar">
						<ProfileAvatar />
					</Group>
				)}

				{!user.user!.isCreator && <DeleteAccount />}
			</Groups>
		</Layout>
	)
}

const Title = styled.h1`
	font-weight: 500;
	font-size: 36px;
	color: #151d48;
	margin-bottom: 24px;

	@media (max-width: 1369px) {
		margin-left: 16px;
		margin-right: 16px;
	}
`

const Groups = styled.div`
	display: flex;
	flex-direction: column;
	gap: 42px;

	@media (max-width: 1369px) {
		padding: 0 16px;
	}
	@media (min-width: 1000px) {
		display: grid;
		grid-template-columns: 1fr 1fr;
	}
`

export default SettingsPage
