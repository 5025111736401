import styled from "styled-components"

import CommunityProfileAvatar from "@forento/shared/components/CommunityProfileAvatar"
import { type PublicCommunityProfile } from "@forento/shared/models/community"

import { useUser } from "~/contexts/UserContext"
import Button from "~/themes/original/components/Button"
import { AuthorityIcon, MessageIcon } from "~/themes/original/components/Icon"
import routes from "~/utilities/routes"
import { lightTextColor } from "~/utilities/styles"

const Profile: React.FC<{ profile: PublicCommunityProfile }> = ({ profile }) => {
	const user = useUser()

	return (
		<Container>
			<Header>
				<CommunityProfileAvatar profile={profile} />
				<Name>
					{profile.firstName} {profile.lastName}
					{profile.isCreator && <StyledAuthorityIcon />}
				</Name>
			</Header>
			{profile.gamification?.currentLevel != null && (
				<Level>
					Level {profile.gamification.currentLevel.tier} - {profile.gamification.currentLevel.label}
				</Level>
			)}
			{profile.id !== user.user!.communityProfileId && (
				<Actions>
					<Action onClick={routes.community.conversation(profile.id)}>
						<MessageIcon />
					</Action>
				</Actions>
			)}
			<Stats>
				<Stat.Container>
					<Stat.Value>{profile.posts}</Stat.Value>
					<Stat.Label>Posts</Stat.Label>
				</Stat.Container>
				<Stat.Container>
					<Stat.Value>{profile.reactions}</Stat.Value>
					<Stat.Label>Reactions</Stat.Label>
				</Stat.Container>
				<Stat.Container>
					<Stat.Value>{profile.comments}</Stat.Value>
					<Stat.Label>Comments</Stat.Label>
				</Stat.Container>
			</Stats>
		</Container>
	)
}

const Container = styled.div`
	flex: 1 0 0;
	background-color: white;
	padding: 22px;
	border-radius: 8px;
	display: flex;
	flex-direction: column;
	gap: 16px;
`

const Header = styled.div`
	display: flex;
	align-items: center;
	gap: 16px;
`

const Name = styled.h1`
	font-weight: 600;
	font-size: 20px;
	color: #151d48;
	display: flex;
	align-items: center;
	gap: 8px;
`

const StyledAuthorityIcon = styled(AuthorityIcon)`
	width: 24px;
	height: 24px;
`

const Level = styled.p`
	color: ${lightTextColor};
`

const Stats = styled.div`
	width: 100%;
	display: flex;
	gap: 8px;
`

const Stat = {
	Container: styled.div`
		flex: 1 0 0;
		display: flex;
		flex-direction: column;
		align-items: center;
	`,
	Value: styled.p`
		font-size: 26px;
		font-weight: bold;
		color: #151d48;
		margin-bottom: 6px;
	`,
	Label: styled.p`
		font-size: 14px;
		color: #151d48;
	`,
}

const Actions = styled.div`
	display: flex;
	align-items: center;
	gap: 16px;
`

const Action = styled(Button)`
	width: 24px;
	height: 24px;
	color: ${lightTextColor};
`

export default Profile
