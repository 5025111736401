import { useCallback, useMemo, useState } from "react"
import styled from "styled-components"

import { usePlatform } from "~/contexts/PlatformContext"
import withCommunityAuthCheck from "~/hocs/withCommunityAuthCheck"
import Button from "~/themes/school/components/Button"
import ContentSearch from "~/themes/school/components/ContentSearch"
import Layout from "~/themes/school/components/Layout"
import PageHeader, { PageHeaderGroup, PageTitle } from "~/themes/school/components/PageHeader"
import PartialLoadingPage from "~/themes/school/components/PartialLoadingPage"
import Post from "~/themes/school/components/Post"
import CreatePostModal from "~/themes/school/pages/community/CommunityPage/CreatePostModal"
import { useTranslation } from "~/translations"
import { query } from "~/utilities/trpc"

const CommunityPage: React.FC = () => {
	const platform = usePlatform().platform
	const t = useTranslation()

	const [isCreatePostModalOpen, setCreatePostModalOpen] = useState(false)
	const [selectedChannelId, setSelectedChannelId] = useState<number | null>(null)
	const [searchItems, setSearchItems] = useState<number[]>()
	const [postContents, setPostContents] = useState<Record<number, string>>({})

	const channels = query.community.listPersonalizedChannels.useQuery()
	const posts = query.community.listPersonalizedPostsByChannel.useQuery(selectedChannelId)

	const selectedChannel = channels.data?.find(channel => channel.id === selectedChannelId)

	const contentSearchItems = useMemo(
		() =>
			posts.data?.map(post => ({
				id: post.id,
				title: post.title,
				content: postContents[post.id] ?? "",
			})) ?? [],
		[posts.data, postContents],
	)

	const keys = useMemo(() => ["title" as const, "content" as const], [])

	const handlePostContentLoaded = useCallback((postId: number, text: string) => {
		setPostContents(current => ({ ...current, [postId]: text }))
	}, [])

	return (
		<Layout>
			{selectedChannel && (
				<CreatePostModal
					channel={selectedChannel}
					isOpen={isCreatePostModalOpen}
					onClose={() => setCreatePostModalOpen(false)}
					onCreate={posts.refetch}
				/>
			)}
			<PageHeader $matchPageWidth>
				<PageTitle>{t("community.title")}</PageTitle>
				<PageHeaderGroup>
					<ContentSearch keys={keys} items={contentSearchItems} onItemsToShowChange={setSearchItems} />
					{selectedChannel?.userAccess === "post" && (
						<CreatePostButton onClick={() => setCreatePostModalOpen(true)}>
							{t("community.post.create")}
						</CreatePostButton>
					)}
				</PageHeaderGroup>
			</PageHeader>
			{platform.content.multipleChannels &&
				(channels.error ? (
					<p>{t("failedToLoad")}</p>
				) : channels.data === undefined ? (
					<PartialLoadingPage />
				) : (
					<Channels>
						{channels.data.map(channel => (
							<Channel
								key={channel.id}
								variant={selectedChannelId === channel.id ? "primary" : "secondary"}
								onClick={() => setSelectedChannelId(channel.id)}
							>
								{channel.name}
							</Channel>
						))}
					</Channels>
				))}
			{posts.error ? (
				<p>{t("failedToLoad")}</p>
			) : posts.data === undefined ? (
				<PartialLoadingPage />
			) : (
				<Posts>
					{[...posts.data]
						.filter(x => searchItems?.includes(x.id) ?? true)
						.sort((a, b) =>
							searchItems
								? searchItems.indexOf(b.id) - searchItems.indexOf(a.id)
								: b.createDate.getTime() - a.createDate.getTime(),
						)
						.map(x => (
							<Post
								key={x.id}
								post={x}
								onChange={posts.refetch}
								onPostContentLoaded={handlePostContentLoaded}
							/>
						))}
				</Posts>
			)}
		</Layout>
	)
}

const CreatePostButton = styled(Button).attrs({ variant: "primary" })`
	flex: 0 0 auto;
`

const Channels = styled.div`
	max-width: 1400px;
	background-color: white;
	padding: 24px;
	display: flex;
	align-items: center;
	gap: 24px;
	margin-bottom: 24px;
`

const Channel = styled(Button)``

const Posts = styled.div`
	display: flex;
	flex-direction: column;
	gap: 24px;
`

export default withCommunityAuthCheck(CommunityPage)
