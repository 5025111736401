import { type FC, type ReactNode } from "react"
import styled from "styled-components"

import Form from "@forento/shared/components/Form"

type Props = { title: string; onSubmit?(): void; children: ReactNode }
const Group: FC<Props> = ({ title, onSubmit, children }) => {
	const content = (
		<>
			<Title>{title}</Title>
			<Content>{children}</Content>
		</>
	)
	return onSubmit !== undefined ? <Form onSubmit={onSubmit}>{content}</Form> : <div>{content}</div>
}

const Title = styled.h2`
	font-weight: 600;
	font-size: 20px;
	color: #151d48;
	margin-bottom: 24px;
`

const Content = styled.div`
	background: white;
	border-radius: 8px;
	padding: 24px;
	display: flex;
	flex-direction: column;
	gap: 24px;
`

export default Group
