import { type FC } from "react"
import { Navigate, useParams } from "react-router"
import styled from "styled-components"

import { parseNumber } from "@forento/shared/utilities/number"
import { exhaustiveGuard } from "@forento/shared/utilities/switch"

import { useDownloadable } from "~/api/downloadable"
import { PurchaseSuccessModalContextProvider } from "~/contexts/PurchaseSuccessModalContext"
import Button from "~/themes/school/components/Button"
import Hero from "~/themes/school/components/Hero"
import Layout from "~/themes/school/components/Layout"
import PartialLoadingPage from "~/themes/school/components/PartialLoadingPage"
import PurchaseItem from "~/themes/school/components/PurchaseItem"
import { useTranslation } from "~/translations"
import routes from "~/utilities/routes"

const DownloadablePage: FC = () => {
	const downloadableId = parseNumber(useParams().downloadableId) ?? -1
	const t = useTranslation()

	const downloadable = useDownloadable(downloadableId)

	if (downloadable.status === "not-found") return <Navigate to={routes.downloadable.index()} />

	return (
		<PurchaseSuccessModalContextProvider
			content={{
				type: "downloadable",
				isUnlocked: downloadable.status === "unlocked",
				payPalOrderId:
					downloadable.status === "unlocked" &&
					downloadable.unlockedCause === "purchased" &&
					downloadable.order.type === "paypal"
						? downloadable.order.id
						: null,
			}}
		>
			<Layout>
				{downloadable.status === "error" ? (
					<p>{t("failedToLoad")}</p>
				) : downloadable.status === "loading" ? (
					<PartialLoadingPage />
				) : (
					<Hero
						title={downloadable.title}
						htmlDescription={downloadable.longDescription?.html}
						callToAction={
							downloadable.status === "paywall" ? (
								<PurchaseItem
									item={{
										type: "downloadable",
										id: downloadable.id,
										price: downloadable.price,
									}}
									onPurchase={downloadable.reload}
								/>
							) : downloadable.status === "sign-in-required" ? (
								<CallToAction>
									<p>{t("product.onlyForUsers")}</p>
									<ButtonsRow>
										<Button variant="primary" onClick={routes.user.signin()}>
											{t("user.signIn.title")}
										</Button>
										<Button variant="primary" onClick={routes.user.signup()}>
											{t("user.signUp.title")}
										</Button>
									</ButtonsRow>
								</CallToAction>
							) : downloadable.status === "membership-required" ? (
								<CallToAction>
									<p>{t("product.onlyForMembers")}</p>
									<ButtonsRow>
										<Button variant="primary" onClick={routes.membership.index()}>
											{t("membership.title")}
										</Button>
									</ButtonsRow>
								</CallToAction>
							) : downloadable.status === "paused" ? (
								<CallToAction>
									<p>{t("product.paused")}</p>
								</CallToAction>
							) : downloadable.status === "unlocked" ? (
								<Button
									variant="primary"
									onClick={downloadable.download}
									isLoading={downloadable.downloadProgress !== undefined}
								>
									{downloadable.downloadProgress === undefined
										? t("general.download")
										: t("downloadable.downloading", {
												progress: Math.floor(downloadable.downloadProgress * 100),
											})}
								</Button>
							) : (
								exhaustiveGuard(downloadable.status)
							)
						}
						thumbnail={
							downloadable.thumbnailFilePath !== null ? (
								<Thumbnail src={downloadable.thumbnailFilePath} />
							) : undefined
						}
					/>
				)}
			</Layout>
		</PurchaseSuccessModalContextProvider>
	)
}

const Thumbnail = styled.img.attrs({ alt: "Course thumbnail" })`
	width: 100%;
	height: 100%;
	object-fit: cover;
`

const CallToAction = styled.div`
	max-width: 400px;
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 16px;
	text-align: center;
`

const ButtonsRow = styled.div`
	display: flex;
	gap: 16px;

	@media (max-width: 499px) {
		flex-direction: column;
	}
`

export default DownloadablePage
