import { type FC, type JSX, Suspense, lazy } from "react"
import styled from "styled-components"
import Values from "values.js"

import AspectRatio from "@forento/shared/components/AspectRatio"

import { type CourseDetail } from "~/api/course"
import Button from "~/themes/original/components/Button"
import { ArrowIcon } from "~/themes/original/components/Icon"
import PurchaseItem from "~/themes/original/components/PurchaseItem"
import { useTranslation } from "~/translations"
import routes from "~/utilities/routes"
import { lightTextColor } from "~/utilities/styles"

const DefaultThumbnail = lazy(() => import("./DefaultThumbnail"))

const Hero: FC<{ data: Extract<CourseDetail, { status: "success" }>; reload(): void }> = ({ data, reload }) => {
	const t = useTranslation()

	const course = data.course

	const callToAction: JSX.Element = (() => {
		switch (data.button.variant) {
			case "start":
				return (
					<Button variant="primary" onClick={routes.course.player(course.id)}>
						<CallToActionIcon as={ArrowIcon} />
						Start
					</Button>
				)
			case "continue":
				return (
					<Button variant="primary" onClick={routes.course.player(course.id)}>
						<CallToActionIcon as={ArrowIcon} />
						Continue
					</Button>
				)
			case "purchase":
				return (
					<PurchaseItem
						item={{ type: "course", id: course.id, price: data.button.price }}
						onPurchase={reload}
					/>
				)
			case "membership":
				return (
					<>
						<InfoText>This course is only available through a membership.</InfoText>
						<Button variant="primary" onClick={routes.membership.index()}>
							Membership
						</Button>
					</>
				)
			case "paused":
				return <InfoText>{t("product.paused")}</InfoText>
		}
	})()

	return (
		<Container>
			<Details>
				<Title>{course.title}</Title>
				<Description>{course.shortDescription}</Description>
				{course.status === "unlocked" && course.unlockedCause === "tester" && (
					<Tester>You are a tester of this course.</Tester>
				)}
				{callToAction}
			</Details>
			<ThumbnailContainer>
				<AspectRatio width={{ value: 100, unit: "percent" }} aspectRatio={16 / 12}>
					{course.thumbnailFilePath !== null ? (
						<Thumbnail src={course.thumbnailFilePath} />
					) : (
						<Suspense>
							<DefaultThumbnail />
						</Suspense>
					)}
				</AspectRatio>
			</ThumbnailContainer>
		</Container>
	)
}

const Container = styled.div`
	background-color: ${props => new Values(props.theme.primaryColor).tint(90).hexString()};
	border-radius: 16px;
	display: flex;
	padding: 42px;
	gap: ${42 * 2}px;
	margin-bottom: 42px;
`

const Details = styled.div`
	flex: 1 0 0;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	gap: 22px;
`

const CallToActionIcon = styled.div`
	width: 16px;
	height: 16px;
`

const Title = styled.h1`
	font-weight: 500;
	font-size: 24px;
	color: #151d48;
`

const Description = styled.p`
	font-size: 16px;
	color: ${lightTextColor};
`

const InfoText = styled.p`
	font-size: 16px;
	color: ${lightTextColor};
	font-style: italic;
`

const Tester = styled.p`
	font-size: 16px;
	font-style: italic;
	color: ${lightTextColor};
`

const ThumbnailContainer = styled.div`
	flex: 1 0 0;
	border-radius: 8px;
	overflow: hidden;

	@media (max-width: 999px) {
		display: none;
	}
`

const Thumbnail = styled.img.attrs({ alt: "Course thumbnail" })`
	object-fit: cover;
`

export default Hero
