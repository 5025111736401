import { type FC } from "react"
import { Navigate, useParams } from "react-router"
import styled from "styled-components"
import Values from "values.js"

import AspectRatio from "@forento/shared/components/AspectRatio"
import { parseNumber } from "@forento/shared/utilities/number"
import { exhaustiveGuard } from "@forento/shared/utilities/switch"

import { PurchaseSuccessModalContextProvider } from "~/contexts/PurchaseSuccessModalContext"
import Button from "~/themes/original/components/Button"
import Layout from "~/themes/original/components/Layout"
import PartialLoadingPage from "~/themes/original/components/PartialLoadingPage"
import PurchaseItem from "~/themes/original/components/PurchaseItem"
import routes from "~/utilities/routes"
import { lightTextColor } from "~/utilities/styles"
import { query } from "~/utilities/trpc"

const BundlePage: FC = () => {
	const bundleId = parseNumber(useParams().bundleId) ?? -1

	const { data: bundle, error, refetch } = query.bundle.getPersonalized.useQuery(bundleId ?? -1)

	if (bundle === null) {
		return <Navigate to={routes.bundle.index()} />
	}

	return (
		<PurchaseSuccessModalContextProvider
			content={{
				type: "bundle",
				isUnlocked: bundle?.status === "unlocked",
				payPalOrderId: bundle?.status === "unlocked" && bundle.order.type === "paypal" ? bundle.order.id : null,
			}}
		>
			<Layout>
				{error ? (
					<p>Failed to load bundle.</p>
				) : !bundle ? (
					<PartialLoadingPage />
				) : (
					<Container>
						<Content>
							<Details>
								<Title>{bundle.name}</Title>
								{bundle.longDescription && (
									<Description dangerouslySetInnerHTML={{ __html: bundle.longDescription.html }} />
								)}
								{bundle.status === "unlocked" ? (
									<Button variant="primary" isDisabled>
										You already own this bundle
									</Button>
								) : bundle.status === "paywall" ? (
									<PurchaseItem
										item={{ type: "bundle", id: bundle.id, price: bundle.price }}
										onPurchase={refetch}
									/>
								) : bundle.status === "paused" ? (
									<p>Sales are paused.</p>
								) : (
									exhaustiveGuard(bundle.status)
								)}
							</Details>
							<ThumbnailContainer>
								<AspectRatio width={{ value: 100, unit: "percent" }} aspectRatio={16 / 12}>
									{bundle.thumbnailFilePath ? <Thumbnail src={bundle.thumbnailFilePath} /> : <div />}
								</AspectRatio>
							</ThumbnailContainer>
						</Content>
					</Container>
				)}
			</Layout>
		</PurchaseSuccessModalContextProvider>
	)
}

const Container = styled.div`
	@media (max-width: 999px) {
		margin: 0 16px;
	}
`

const Content = styled.div`
	background-color: ${props => new Values(props.theme.primaryColor).tint(90).hexString()};
	border-radius: 16px;
	display: flex;
	padding: 42px;
	gap: ${42 * 2}px;
	margin-bottom: 42px;
`

const Details = styled.div`
	flex: 1 0 0;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	gap: 22px;
`

const Title = styled.h1`
	font-weight: 500;
	font-size: 24px;
	color: #151d48;
`

const Description = styled.div`
	font-size: 16px;
	color: ${lightTextColor};
`

const ThumbnailContainer = styled.div`
	flex: 1 0 0;
	border-radius: 8px;
	overflow: hidden;

	@media (max-width: 999px) {
		display: none;
	}
`

const Thumbnail = styled.img.attrs({ alt: "Course thumbnail" })`
	object-fit: cover;
`

export default BundlePage
